import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import moment from 'moment';
import { UserJourneyStageId } from './../config/userJourneyMap';
const { firestore } = firebase;

enum FirestoreCollection {
  InsurancePlans = 'insurancePlans',
  Content = 'content',
  CustomSteps = 'customSteps',
  Forex = 'forex',
  Notifications = 'notifications',
  PredefinedSteps = 'predefinedSteps',
  Resources = 'resources',
  SopTags = 'sopTag',
  Universities = 'universities',
  UserAcademicInformation = 'userAcademicInformation',
  UserApplicationInterests = 'userApplicationInterest',
  UserApplications = 'userApplications',
  UserGmatScores = 'userGmatScores',
  UserGreScores = 'userGreScores',
  UserInformation = 'userInformation',
  UserProfiles = 'userProfiles',
  UserResumeVersion = 'userResumeVersion',
  UserSopVersion = 'userSopVersion',
  UserStepsStatus = 'userStepsStatus',
  UserToeflScores = 'userToeflScores',
  UserWorkInformation = 'userWorkInformation',
  WebinarRegistrations = 'webinarRegistrations',
  Slots = 'slots',
  Insurance = 'insurance',
  GroupCodes = 'groupCodes',
  WellAwayPastPolicies = 'wellAwayPastPolicies',
}

enum InsuranceDocument {
  GBGRequests = 'gbgRequests',
  Requests = 'requests',
  Plans = 'plans',
  Universities = 'universities',
  TypeformAnswers = 'typeform-ans',
  FileWaiver = 'fileWaiver',
  DownloadWellAway = 'downloadWellAway',
  NPSFeedback = 'NPSFeedback',
  WellAway = 'WellAway',
  GBG = 'GBG',
}

export const FirestoreReference = {
  Insurance: () => firestore().collection(FirestoreCollection.Insurance),
  InsuranceWellAwayRequests: () => FirestoreReference.Insurance().doc(InsuranceDocument.Requests).collection(InsuranceDocument.WellAway),
  InsuranceGBGRequests: () => FirestoreReference.Insurance().doc(InsuranceDocument.Requests).collection(InsuranceDocument.GBG),
  InsurancePlans: () => firestore().collection(FirestoreCollection.InsurancePlans),
  InsurancePlan: (planID: string) => FirestoreReference.InsurancePlans().doc(planID),
  Slots: () => firestore().collection(FirestoreCollection.Slots),
  Notifications: () => firestore().collection(FirestoreCollection.Notifications),
  Forex: () => firestore().collection(FirestoreCollection.Forex),
  UserProfiles: () => firestore().collection(FirestoreCollection.UserProfiles),
  UserStepsStatus: (userId: string) => firestore().collection(FirestoreCollection.UserStepsStatus).doc(userId),
  WebinarRegistrations: () => firestore().collection(FirestoreCollection.WebinarRegistrations),
  WebinarRegistration: (registrationId: string) => firestore().collection(FirestoreCollection.WebinarRegistrations).doc(registrationId),
  UserInformation: () => firestore().collection(FirestoreCollection.UserInformation),
  UserAcademicInformation: () => firestore().collection(FirestoreCollection.UserAcademicInformation),
  UserWorkInformation: () => firestore().collection(FirestoreCollection.UserWorkInformation),
  UserApplicationInterests: () => firestore().collection(FirestoreCollection.UserApplicationInterests),
  UserApplications: () => firestore().collection(FirestoreCollection.UserApplications),
  LoanApplication: (userID: string) => FirestoreReference.UserApplications().doc(UserJourneyStageId.Loans).collection(userID),
  VisaApplications: (userID: string) => FirestoreReference.UserApplications().doc(UserJourneyStageId.VisaApplication).collection(userID),
  UserSopVersion: () => firestore().collection(FirestoreCollection.UserSopVersion),
  UserResumeVersion: () => firestore().collection(FirestoreCollection.UserResumeVersion),
  UserGreScores: () => firestore().collection(FirestoreCollection.UserGreScores),
  UserGmatScores: () => firestore().collection(FirestoreCollection.UserGmatScores),
  UserToeflScores: () => firestore().collection(FirestoreCollection.UserToeflScores),
  SopTags: () => firestore().collection(FirestoreCollection.SopTags),
  CustomSteps: () => firestore().collection(FirestoreCollection.CustomSteps),
  PredefinedSteps: () => firestore().collection(FirestoreCollection.PredefinedSteps),
  CustomStep: (stepId: string) => firestore().collection(FirestoreCollection.CustomSteps).doc(stepId),
  Content: () => firestore().collection(FirestoreCollection.Content),
  Universities: () => firestore().collection(FirestoreCollection.Universities),
  Resources: () => firestore().collection(FirestoreCollection.Resources),
  InsuranceRequests: (year?: string) =>
    firestore()
      .collection(FirestoreCollection.Insurance)
      .doc(InsuranceDocument.Requests)
      .collection(year || moment().format('YYYY')),
  GroupCodes: () => firestore().collection(FirestoreCollection.GroupCodes),
  WellAwayPastPolicies: (userId: string) => firestore().collection(FirestoreCollection.WellAwayPastPolicies).doc(userId),
};
