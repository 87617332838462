import React, { useEffect } from 'react';
import { Row, Radio, Col, Button, message } from 'antd';
import { PlanType } from '../../repos';
import Styles from './ViewAll.module.sass';
import { ComparisonData } from './HealthInsuranceStepContainer';
import { BankOutlined, UserOutlined, FileDoneOutlined, PhoneOutlined, QuestionCircleOutlined, SolutionOutlined } from '@ant-design/icons';
import moment from 'moment';
import { navigate } from '@reach/router';
import { Alert, AlertType, DocsHelpModal, HelpDataType, InstructionalModal, IntercomAPI, PlanDetailsModal } from '../../components';
import { RootState, setAvailablePlans, setSelectedPlan } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { calPremiumWithData, getPlans } from './helpers';
import { useMobile } from '../../services';
import { ErrorScreen } from './ErrorScreen';
import { GradlyLoading } from '../../App';

interface ViewAllViewProps {
  comparisonData: ComparisonData;
}

enum InsurancePeriod {
  Monthly = 'Monthly',
  Annual = 'Yearly',
  Custom = 'Custom',
}
interface PlanDetailsProps {
  plan: PlanType;
  premium: number | string;
  period: string;
}

const planIcons: any = {
  'Gradly Alpha': 'alpha',
  'Gradly Beta': 'beta',
  'Gradly Bronze': 'bronze',
  'Gradly Chi': 'chi',
  'Gradly Delta': 'delta',
  'Gradly Diamond': 'diamond',
  'Gradly Elite': 'elite',
  'Gradly Emerald': 'emerald',
  'Gradly Epsilon': 'epsilon',
  'Gradly Eta': 'eta',
  'Gradly Gamma': 'gamma',
  'Gradly Gold': 'gold',
  'Gradly Immune 100': 'immune',
  'Gradly Immune 500': 'immune',
  'Gradly Immune ISP 100': 'immune-isp',
  'Gradly Immune ISP 500': 'immune-isp',
  'Gradly Immune Plus 100': 'immune-plus',
  'Gradly Immune Plus 50': 'immune-plus',
  'Gradly Iota': 'iota',
  'Gradly Kappa': 'kappa',
  'Gradly Lambda': 'lambda',
  'Gradly Lite': 'lite',
  'Gradly Magnum': 'magnum',
  'Gradly Opal 400': 'opal',
  'Gradly Opal 90': 'opal',
  'Gradly Pearl': 'pearl',
  'Gradly Phi': 'phi',
  'Gradly Platinum 400': 'platinum',
  'Gradly Platinum 90': 'platinum',
  'Gradly Ruby': 'ruby',
  'Gradly Sapphire 100': 'sapphire',
  'Gradly Sapphire 500': 'sapphire',
  'Gradly Secure': 'secure',
  'Gradly Sigma': 'sigma',
  'Gradly Silver': 'silver',
  'Gradly Supreme': 'supreme',
  'Gradly Topaz': 'topaz',
  'Gradly Zeta Plus': 'zeta-plus',
  'Gradly Zeta': 'zeta',
  'Gradly Student World Elite 250': 'world-elite-250',
  'Gradly Student World Elite 450': 'world-elite-450',
  'Gradly Student World Elite Plus': 'world-elite-plus',
};

const PlanDetails = (props: PlanDetailsProps) => {
  const { plan, premium, period } = props;
  const dispatch = useDispatch();
  const [showHelp, setShowHelp] = React.useState(false);
  const [helpID, setHelpID] = React.useState('deductible');
  const [showDocs, setShowDocs] = React.useState(false);
  const [showplan, setShowPlan] = React.useState<{ title: string; brochure: string }>();
  const [showPlanDetails, setShowPlanDetails] = React.useState(false);

  const helpData: HelpDataType = {
    deductible: {
      title: 'Annual Deductible',
      description:
        'A deductible is what you pay before your insurance becomes active. So a $100 annual deductible means, you would pay the first $100 of any medical expenses. After the $100, your insurance will begin covering your costs. Most deductibles are annual, meaning that you cover the first $100 of expenses for the entire year. But $100 per condition means you will need to cover $100 of every time you see a doctor for a new medical condition',
    },
    coinsurance: {
      title: 'Coinsurance',
      description:
        'Coinsurance is the percentage of medical services that you have to pay for. A higher coinsurance (80%) means that you need only to pay for 20% of medical services, whereas a lower coinsurance (60%) means that you need to pay for 40% of medical services. This coinsurance applies for in-network providers in the US or expenses outside the US.',
    },
    outofpocket: {
      title: 'Out of Pocket',
      description: 'An out-of-pocket maximum is the most you will have to pay under your health insurance plan.',
    },
  };

  return (
    <Col span={24}>
      <PlanDetailsModal visible={showPlanDetails} setVisible={setShowPlanDetails} plan={plan} />
      <DocsHelpModal helpData={helpData[helpID]} showHelp={showHelp} setShowHelp={setShowHelp}></DocsHelpModal>
      <InstructionalModal
        visible={showDocs}
        setVisible={setShowDocs}
        type={'article'}
        url={showplan?.brochure || ''}
        title={
          <>
            <SolutionOutlined /> {showplan?.title}: Insurance Plan
          </>
        }
        ctaQuestion={'Have questions about the plan?'}
        hideSecondaryTitle
        chatText={'Chat with us'}
        intercomMsg={'Hey! I need help with my insurance brochure.'}
      />
      <Row justify="center" align="middle" className={Styles.planDetails + (plan.recommended ? ' ' + Styles.recommended : '')}>
        <Col xs={0} lg={3}>
          <img alt={plan.retailName} src={`https://insurance.gradly.us/assets/images/pricing/${planIcons[plan.retailName]}.svg`}></img>
        </Col>
        <Col xs={24} lg={5}>
          <h1>{plan.retailName}</h1>
        </Col>
        <Col xs={{ span: 24, order: 1 }} lg={6}>
          <ul>
            <li>
              <span>${plan.planFeatures.deductible}</span> annual deductible{' '}
              <QuestionCircleOutlined
                onClick={() => {
                  setHelpID('deductible');
                  setShowHelp(true);
                }}
              />
            </li>
            <li>
              <span>{plan.planFeatures.coInsurance * 100}%</span> coinsurance{' '}
              <QuestionCircleOutlined
                onClick={() => {
                  setHelpID('coinsurance');
                  setShowHelp(true);
                }}
              />
            </li>
            <li>
              <span>$ {plan.planFeatures.outOfPocketMax}</span> out of pocket maximum{' '}
              <QuestionCircleOutlined
                onClick={() => {
                  setHelpID('outofpocket');
                  setShowHelp(true);
                }}
              />
            </li>
          </ul>
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 4, order: 2 }}>
          <span className={Styles.premium}>${premium}</span>
          <span className={Styles.premiumPeriod}>{period}</span>
        </Col>
        <Col xs={{ span: 24, order: 2 }} lg={5}>
          <Row justify="end" className={Styles.planButtons}>
            <Button
              type="primary"
              onClick={() => {
                dispatch(setSelectedPlan(plan));
                navigate!(`purchase-insurance`);
              }}>
              Purchase
            </Button>
            <Button
              onClick={() => {
                setShowPlan({ brochure: plan.brochureURL, title: plan.retailName });
                setShowDocs(true);
                // dispatch(setSelectedPlan(plan));
                // setShowPlanDetails(true);
              }}>
              View Brochure
            </Button>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export const ViewAllView: React.FC<ViewAllViewProps> = (props) => {
  const { comparisonData } = props;
  const { university, dob, visa_type, num_months } = comparisonData;
  const age = moment().diff(dob, 'years');
  const [period, setPeriod] = React.useState(InsurancePeriod.Annual);
  const [help, setHelp] = React.useState(false);
  const dispatch = useDispatch();
  const plans = useSelector((state: RootState) => state.Insurance.availablePlans);
  const isMobile = useMobile();

  const setPlans = (plan: PlanType[]) => {
    dispatch(setAvailablePlans(plan.filter((plan) => (_getPremium(plan) || 0) > 0)));
  };

  useEffect(() => {
    if (comparisonData) getPlans(university, setPlans);
  }, [university, university.plans]);

  function getPeriod() {
    switch (period) {
      case InsurancePeriod.Monthly:
        return 'per month';
      case InsurancePeriod.Annual:
        return 'for 12 months';
      case InsurancePeriod.Custom:
        return `${moment().format('MMM Do')} to ${moment().add(num_months, 'months').format('MMM Do')}`;
      default:
        return 'N/A';
    }
  }
  function getPremium(plan: PlanType) {
    return parseFloat((_getPremium(plan) || 0).toString())
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function _getPremium(plan: PlanType) {
    if (age < 17) {
      message.error({
        content: 'Please contact support for health insurance for your age',
        key: 'age',
      });
      return;
    }

    const annualPremiumAmount = calPremiumWithData(comparisonData.dob.format('YYYY-MM-DD'), comparisonData.university, plan, moment(), moment().add(1, 'year').subtract(1, 'day'));

    switch (period) {
      case InsurancePeriod.Monthly:
        return annualPremiumAmount / 12;
      case InsurancePeriod.Annual:
        return annualPremiumAmount;
      case InsurancePeriod.Custom:
        return (annualPremiumAmount / 12) * num_months;
      default:
        return -1;
    }
  }
  if (!plans) return <GradlyLoading />;
  if (plans.length > 0)
    return (
      <Row className={Styles.container}>
        <InstructionalModal
          visible={help}
          setVisible={setHelp}
          type={'article'}
          url={'https://insurance.gradly.us/terms'}
          title={'Terms and Terminologies'}
          chatText={'Chat with our insurance expert'}></InstructionalModal>
        {!isMobile && (
          <Alert
            message={
              <>
                To understand health insurance terms and terminologies, <a onClick={() => setHelp(true)}>read this guide</a>.
              </>
            }
            type={AlertType.Info}
          />
        )}
        <Row className={Styles.help}>
          <p>Need personalized help?</p>{' '}
          <Button
            onClick={() => {
              IntercomAPI.showWithMessagePreFilled(`Hey! I want to schedule a call for health insurnace.`, false);
            }}
            type="primary"
            icon={<PhoneOutlined />}>
            Schedule a call
          </Button>
        </Row>
        <Row justify="center" className={Styles.header}>
          <h3>Academic Year 2021-2022</h3>
          <img src="https://res.cloudinary.com/gradly/image/upload/v1651140854/logos/unitedHealthcare_kbnb6o.png" alt="UnitedHealthCare"></img>
          <h1>UnitedHealthCare Options PPO</h1>
          <Row wrap={false} justify={'center'} align="middle" className={Styles.compareParams}>
            <Col xs={12} lg={8}>
              <BankOutlined />
              {university.name}
            </Col>
            <Col xs={8} lg={8}>
              <UserOutlined />
              {age} years old
            </Col>
            <Col xs={4} lg={8}>
              <FileDoneOutlined />
              {visa_type}
            </Col>
          </Row>
          <Radio.Group
            className={Styles.periodRadio}
            buttonStyle="solid"
            value={period}
            onChange={(value) => {
              setPeriod(value.target.value as InsurancePeriod);
            }}>
            <Radio.Button value={InsurancePeriod.Monthly}>{InsurancePeriod.Monthly}</Radio.Button>
            <Radio.Button value={InsurancePeriod.Annual}>{InsurancePeriod.Annual}</Radio.Button>
            <Radio.Button value={InsurancePeriod.Custom}>{num_months} months</Radio.Button>
          </Radio.Group>
        </Row>
        <Row gutter={[20, 20]} justify="center" className={Styles.plansContainer}>
          {plans.map((plan, index) => (
            <PlanDetails key={index} plan={plan} premium={getPremium(plan)} period={getPeriod()} />
          ))}
        </Row>
      </Row>
    );
  else return <ErrorScreen />;
};
