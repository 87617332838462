import { BankOutlined, CalendarOutlined, ClockCircleOutlined, FileDoneOutlined, RightOutlined } from '@ant-design/icons';
import { Form, Select, InputNumber, Button, Row, message, DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import { University } from '../../repos';
import { UniversityInput } from '../UniversityInput';
import Styles from './NaturalForm.module.sass';

interface InsuranceDetailsFormProps {
  onSubmit: (values: any) => void;
  university?: University;
}

export const InsuranceDetailsForm: React.FC<InsuranceDetailsFormProps> = (props) => {
  return (
    <div className={Styles.insuranceContainer}>
      <Form
        onFinish={(data) => {
          if (!data.university) {
            message.error('Please select your university');
            return;
          }
          if (!data.num_months) {
            message.error('Please enter the number of months you want to enroll');
            return;
          }
          if (data.num_months < 4 || data.num_months > 12) {
            message.error('Please enter a number of months between 4 and 12');
            return;
          }
          if (!data.dob) {
            message.error('Please select your date of birth');
            return;
          }
          if (!data.visa_type) {
            message.error('Please select your visa type');
            return;
          }
          props.onSubmit({ ...data, university: data.university[0] });
        }}
        layout="inline">
        <div className={Styles.formText}>
          I am enrolled in <BankOutlined />{' '}
          <Form.Item required noStyle name="university">
            <UniversityInput
              defaultSelectedUniversities={props.university ? [props.university] : undefined}
              multiple={false}
              showArrow={false}
              className={Styles.uniSelect}
              optionsClassName={Styles.naturalOptions}></UniversityInput>
          </Form.Item>
          .<br></br>I want to enroll in a health insurance plan for <ClockCircleOutlined />{' '}
          <Form.Item noStyle name="num_months">
            <InputNumber style={{ width: 42 }} className={Styles.numberInput} placeholder="12" controls={false} bordered={false}></InputNumber>
          </Form.Item>{' '}
          months, my date of birth is <CalendarOutlined />
          <Form.Item noStyle name="dob">
            <DatePicker
              placeholder="select date"
              className={Styles.datePicker}
              bordered={false}
              disabledDate={(date) => {
                return moment().diff(date, 'y') < 17;
              }}
            />
          </Form.Item>
          and I am on <FileDoneOutlined />
          <Form.Item required noStyle name="visa_type">
            <Select
              style={{ minWidth: 72 }}
              placeholder="F1"
              showArrow={false}
              className={Styles.naturalSelect}
              dropdownClassName={Styles.naturalOptions}
              bordered={false}
              options={[
                { label: 'F1', value: 'F1' },
                { label: 'J1', value: 'J1' },
                { label: 'OPT', value: 'OPT' },
                { label: 'H-1B', value: 'H-1B' },
                { label: 'O1', value: 'O1' },
                { label: 'M1', value: 'M1' },
              ]}>
              {' '}
            </Select>
          </Form.Item>
          visa.
        </div>
        <Form.Item style={{ marginTop: 32 }}>
          <Row justify="center">
            <Button type="primary" htmlType="submit">
              SUBMIT <RightOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};
