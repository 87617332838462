import firebase from 'firebase/compat';
export interface EnrollmentRequestWellAwayType {
  RequestId: string;
  TransactionType: string;
  TransactionDate: string;
  GroupId: string;
  GroupNumber: any;
  EnrollmentIdentifier?: string;
  PolicyNumber: string;
  PolicyId: string;
  AgentNumber: any;
  PlanName: string;
  HomeCountryCode: string;
  CurrencyCode: string;
  Members: Member[];
  Destinations: string[];
  HomeAddress: Address;
  BillingAddress: Address;
  Options?: any;
  Maternity: boolean;
}

export interface Member {
  EnrollmentType: string;
  IndividualId: string;
  PrimaryMemberId: string;
  FirstName: string;
  MiddleName: any;
  LastName: string;
  DOB: string;
  Gender: string;
  MemberRelationship: string;
  MaritalStatus: string;
  Coverage: Coverage;
  Contacts: Contact[];
  Options?: any;
}

export interface Coverage {
  PurchaseDate: string;
  CoverageStartDate: string;
  CoverageEndDate: string;
  Premium: number;
  Dental: boolean;
  Vision: boolean;
}

export interface Contact {
  Name: string;
  Value: string;
}

export interface Address {
  Address1: string;
  Address2?: string;
  City: string;
  StateCode: string;
  CountryCode: string;
  PostalCode: string;
}

export interface InsuranceRequestWellAwayType {
  cancel?: boolean;
  requestID: string;
  userID: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  usMobileNumber?: string;
  dob: string;
  nationality: string;
  destinationCountryCode: string;
  destinationStateCode: string;
  university: string;
  universityID: string;
  studentId: string;
  insurancePlan: string;
  insurancePlanID: string;
  enrolledIndividualId?: string;
  cardLink?: string;
  cardLongURL?: string;
  docspringID?: string;
  homeCountryCode: string;
  gender: string;
  maritalStatus: string;
  coverageStartDate?: string;
  coverageEndDate?: string;
  numberOfMonths: number;
  dental?: boolean;
  vision?: boolean;
  homeAddress1: string;
  homeAddress2?: string;
  homeCity?: string;
  homeStateCode?: string;
  homePostalCode?: string;
  destinationAddress1: string;
  destinationAddress2?: string;
  destinationCity?: string;
  destinationPostalCode?: string;
  createdAt?: firebase.firestore.FieldValue;
  depositConfirmed?: boolean;
  year: number;
  status: InsuranceRequestStatus;
  partner: InsuranceRequestPartner;
  purchaseStartDate: string;
  invoiceURL?: string;
  invoiceID?: string;
  invoicePaid?: boolean;
  finalPremium?: number;
  securityQuestion1?: string;
  securityQuestion2?: string;
  securityAns1?: string;
  securityAns2?: string;
  testMe?: boolean;
  groupCode?: string;
}
export interface InsuranceRequestGBGType extends InsuranceRequestWellAwayType {
  coveragePeriod: string;
  graduationYear: number;
  passportCountry: string;
  gender: 'Unknown' | 'Male' | 'Female' | 'Other';
  maritalStatus: 'Unknown' | 'Single' | 'Married' | 'Divorced' | 'Widowed' | 'Other';
  responseRequestId?: string;
  isPartOfGroupPlan?: boolean;
  memberId: string;
  uhcId?: number;
  enrollmentIdentifier?: string;
  enrolledIndividualId?: string;
  policyPeriodNumber?: string;
}
export interface GBGEnrollmentRequestType {
  TransactionType: 'ADD' | 'CHANGE' | 'CANCEL';
  TransactionDate?: string;
  PolicyNumber: string;
  HomeCountryCode: string;
  Member: {
    FirstName: string;
    MiddleName?: string;
    LastName: string;
    DOB: string;
    Gender: 'Unknown' | 'Male' | 'Female' | 'Other';
    MaritalStatus?: 'Unknown' | 'Single' | 'Married' | 'Divorced' | 'Widowed' | 'Other';
    Coverage: {
      EnrollmentType: 'ADD' | 'CHANGE' | 'CANCEL';
      PurchaseDate?: string;
      CoverageStartDate?: string;
      CoverageEndDate?: string;
      Premium: number;
    };
    Contacts: {
      Name: 'Email' | 'Phone';
      Value: string;
    }[];
  };
  Destinations: string[];
  HomeAddress: {
    Address1: string;
    StateCode?: string;
    CountryCode: string;
  };
}

export interface GBGEnrollmentResponseType {
  RequestId: string;
  Status: string;
  EnrollmentIdentifier: string;
  PolicyPeriodNumber: string;
  Member: {
    RequestedIndividualId: string;
    EnrolledIndividualId: string;
    UHCID: number;
  };
  Errors: {
    Code: string;
    Description: string;
  }[];
}

export enum InsuranceRequestStatus {
  PesonalisedPlansReceived = 'PesonalisedPlansReceived',
  WaiverApplicationFiled = 'WaiverApplicationFiled',
  WaiverApplicationApproved = 'WaiverApplicationApproved',
  PaidFullPremium = 'PaidFullPremium',
  PaidMonthlyPremium = 'PaidMonthlyPremium',
  ReceivedIDCard = 'ReceivedIDCard',
  UHCIDCreated = 'UHCIDCreated',
}

export enum InsuranceRequestPartner {
  WellAway = 'WellAway',
  GBG = 'GBG',
}
