import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { UserJourneyStages } from '../../config/userJourneyMap';
import { RootState, updateCustomStepStatus } from '../../redux';
import { ConsumeContentStep, CustomStep, FillFormStep, StepStatus, StepType } from '../../repos';
import { Analytics, AnalyticsEventName, reportError } from '../../utils';
import { AlertProps } from '../Alert';
import './CustomStep.module.sass';
import {
  ConsumeContentStepView,
  CustomStepView,
  FillFormStepView,
  getConsumeContentAlert,
  getCustomStepAlert,
  getFillFormAlert,
} from './CustomStepTypes';

interface Props {
  step: CustomStep;
  onCompleted?: any;
  updateStatus: typeof updateCustomStepStatus;
}

const getIcon = (type: StepType) => {
  switch (type) {
    case StepType.ConsumeContent:
      return 'file-search';
    case StepType.RsvpToWebinar:
      return 'video-camera';
    case StepType.FillForm:
      return 'form';
    case StepType.Custom:
      return 'bars';
    default:
      reportError(new Error(`Custom step of unknown type (${type}) found`));
      break;
  }
};

const getAlert = (step: CustomStep): AlertProps | undefined => {
  switch (step.type) {
    case StepType.ConsumeContent:
      return getConsumeContentAlert(step as ConsumeContentStep);
    case StepType.RsvpToWebinar:
      return;
    case StepType.FillForm:
      return getFillFormAlert(step as FillFormStep);
    case StepType.Custom:
      return getCustomStepAlert(step);
    default:
      reportError(new Error(`Custom step of unknown type (${step.type}) found`));
      break;
  }
};

const getStatusText = (step: CustomStep): string | undefined => {
  switch (step.type) {
    case StepType.ConsumeContent:
      return;
    case StepType.RsvpToWebinar:
      return;
    case StepType.FillForm:
      return;
    case StepType.Custom:
      return;
    default:
      reportError(new Error(`Custom step of unknown type (${step.type}) found`));
      break;
  }
};

const logAnalyticsForCustomStepActionPerformed = (
  step: CustomStep,
  actionText = 'Mark as complete',
) => {
  const { status, type, title, associatedStageId, stageProgressionOn } = step;
  if (status === StepStatus.Locked) {
    return;
  }
  Analytics.log(AnalyticsEventName.CustomStepActionPerformed, {
    status,
    type,
    title,
    actionText,
    associatedStage: UserJourneyStages[associatedStageId].title,
    timeInSeconds: moment().diff(stageProgressionOn[status], 'seconds'),
  });
};

class CustomStepRenderer extends React.PureComponent<Props> {
  static getIcon = getIcon;
  static getAlert = getAlert;
  static getStatusText = getStatusText;

  render() {
    const { step, updateStatus } = this.props;
    switch (step.type) {
      case StepType.ConsumeContent:
        return (
          <ConsumeContentStepView
            step={step as ConsumeContentStep}
            onMarkAsComplete={() => {
              updateStatus(step.id, StepStatus.Complete, { finishedReading: true });
              logAnalyticsForCustomStepActionPerformed(step);
            }}
          />
        );
      case StepType.FillForm:
        return (
          <FillFormStepView
            step={step as FillFormStep}
            onSubmit={() => {
              updateStatus(step.id, StepStatus.Processing);
              logAnalyticsForCustomStepActionPerformed(step, 'Submit Form');
            }}
            onMarkAsComplete={() => {
              updateStatus(step.id, StepStatus.Complete);
              logAnalyticsForCustomStepActionPerformed(step);
            }}
          />
        );
      case StepType.Custom:
        return (
          <CustomStepView
            step={step}
            onSubmit={() => {
              logAnalyticsForCustomStepActionPerformed(step);
            }}
          />
        );
      default:
        reportError(new Error(`Custom step of unknown type (${step.type}) found`));
        return <div>''</div>;
    }
  }
}

const mapStateToProps = (state: RootState) => ({});

export default connect(mapStateToProps, { updateStatus: updateCustomStepStatus })(
  CustomStepRenderer,
);
