import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Accordion, WelcomeUserWithProgressHeading, AttendWebinarAccordionItem } from '../../components';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { RootState, setInsuranceData, setSelectedPlan } from '../../redux';
import {
  CustomStep,
  InsuranceRequestGBGType,
  InsuranceRequestWellAwayType,
  markStepsStatus,
  PlanType,
  PredefinedStep,
  RsvpStep,
  StagePredefinedSteps,
  StepStatus,
  University,
  UserProfile,
} from '../../repos';
import { renderCustomStep } from '../Home';
import { getIndexFromRoute } from '../utils';
import { useStepCalculation } from '../utils/stepCalculationHooks';
import { ViewAllView } from './ViewAll';
import Styles from './HealthInsuranceStepContainer.module.sass';
import { WelcomeScreen } from './WelcomeScreen';
import { EnterDetails } from './EnterDetails';
import { Moment } from 'moment';
import moment from 'moment';
import WaiverGuide from './WaiverGuide';
import { FirestoreReference } from '../../utils';
import { InsuranceDocs } from './InsuranceDocs';
import { RightOutlined } from '@ant-design/icons';
import { FileInsurance } from './FileInsurance';
import Cookies from 'js-cookie';
import { GBGPurchaseForm } from './GBGPurchaseForm';
import { PayInvoice } from './PayInvoice';
import { WellAwayPurchaseForm } from './WellAwayPurchaseForm';
import { ViewRecommended } from './ViewRecommended';
interface HealthInsuranceStepContainerProps extends RouteComponentProps {
  customSteps: CustomStep[];
  currentUser?: UserProfile | null;
  allPredefinedSteps: StagePredefinedSteps;
}

export interface ComparisonData {
  university: University;
  dob: Moment;
  visa_type: string;
  num_months: number;
  email?: string;
}

export enum StepID {
  EnterDetails = 'EnterDetails',
  ViewRecommended = 'ViewRecommended',
  ViewAll = 'ViewAll',
  AttendWebinar = 'AttendWebinar',
  PurchaseInsurance = 'PurchaseInsurance',
  WaiverGuide = 'WaiverGuide',
  PayInvoice = 'PayInvoice',
  YourInsurance = 'YourInsurance',
  FileInsuranceClaim = 'FileInsuranceClaim',
}

const HealthInsuranceStepContainer: React.FC<HealthInsuranceStepContainerProps> = (props) => {
  const { customSteps, currentUser, allPredefinedSteps } = props;
  const AccordionInstance = useRef<Accordion>(null);
  const { pathname } = useLocation();
  const [comparisonData, setComparisonData] = useState<ComparisonData>();
  const [showWelcomeScreen, setShowWelcomeScreen] = useState<boolean>(false);
  const plan = useSelector((state: RootState) => state.Insurance.selectedPlan);
  let modifiedPredefinedSteps = { ...allPredefinedSteps };
  const insuranceData = useSelector((state: RootState) => state.Insurance.insuranceData);

  if (currentUser?.isAnonymous || comparisonData?.dob.isValid()) {
    modifiedPredefinedSteps = {
      ...modifiedPredefinedSteps,
      [StepID.PurchaseInsurance]: {
        ...modifiedPredefinedSteps[StepID.PurchaseInsurance],
        order: -1,
      },
    };
  }
  if (insuranceData && insuranceData.cardLongURL) {
    modifiedPredefinedSteps = {
      ...modifiedPredefinedSteps,
      [StepID.YourInsurance]: {
        ...modifiedPredefinedSteps[StepID.YourInsurance],
        order: -2,
      },
    };
  }

  const { steps, stepsLeft, calculatedStepsCompleted, setCalculatedStepsCompleted, predefinedStepsStatus } = useStepCalculation(
    Object.values(StepID),
    modifiedPredefinedSteps,
    customSteps,
    UserJourneyStageId.HealthInsurance,
    showWelcomeScreen,
    currentUser?.id,
  );

  const selectedPlan = useSelector((state: RootState) => state.Insurance.selectedPlan);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      FirestoreReference.InsuranceGBGRequests()
        .where('userID', '==', currentUser.id)
        .onSnapshot(
          (snapshot) => {
            snapshot.forEach((doc) => {
              const insuranceData = doc.data() as InsuranceRequestGBGType;
              dispatch(setInsuranceData(insuranceData));
              FirestoreReference.InsurancePlan(insuranceData.insurancePlanID)
                .get()
                .then((doc) => {
                  const data = doc.data() as PlanType;
                  dispatch(setSelectedPlan({ ...data, planID: doc.id }));
                });
            });
          },
          (error) => {
            console.debug('ERROR InsuranceGBGRequests', error);
          },
        );
      FirestoreReference.InsuranceWellAwayRequests()
        .where('userID', '==', currentUser.id)
        .onSnapshot(
          (snapshot) => {
            snapshot.forEach((doc) => {
              const insuranceData = doc.data() as InsuranceRequestWellAwayType;
              dispatch(setInsuranceData(insuranceData));
              FirestoreReference.InsurancePlan(insuranceData.insurancePlanID)
                .get()
                .then((doc) => {
                  const data = doc.data() as PlanType;
                  dispatch(setSelectedPlan({ ...data, planID: doc.id }));
                });
            });
          },
          (error) => {
            console.debug('ERROR InsuranceWellAwayRequests', error);
          },
        );

      const { universityID, email, dob, planID } = {
        universityID: Cookies.get('universityID'),
        email: Cookies.get('email'),
        dob: Cookies.get('dob'),
        planID: Cookies.get('planID'),
      };
      if (universityID) {
        FirestoreReference.Universities()
          .doc(universityID)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setComparisonData({
                university: { ...(doc.data() as University), id: universityID },
                dob: moment(dob, 'YYYY-MM-DD'),
                visa_type: 'F1',
                num_months: 8,
                email,
              });
              if (planID || insuranceData) navigate(`/${UserJourneyStageId.HealthInsurance}/purchase-insurance`);
              else if (universityID && dob) navigate(`/${UserJourneyStageId.HealthInsurance}/view-plans`);
              else navigate(`/${UserJourneyStageId.HealthInsurance}/enter-details`);
            }
          });
      }
      if (planID) {
        FirestoreReference.InsurancePlans()
          .doc(planID)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const data = doc.data() as PlanType;
              dispatch(setSelectedPlan({ ...data, planID }));
            }
          });
      }
      if (currentUser.isAnonymous && currentUser.id !== 'temp') {
        markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.EnterDetails], StepStatus.Pending);
      }
    }
  }, [currentUser, currentUser?.id]);

  useEffect(() => {
    if (!insuranceData || !currentUser || !predefinedStepsStatus) return;
    if (predefinedStepsStatus[StepID.WaiverGuide] && !predefinedStepsStatus[StepID.WaiverGuide].waiverGuide) {
      FirestoreReference.Universities()
        .doc(insuranceData.universityID)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const university = doc.data() as University;
            markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.WaiverGuide], StepStatus.ReadyForReview, { waiverGuide: university.waiverGuide || 'NA' });
          }
        });
    }

    if (insuranceData && currentUser) {
      markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.EnterDetails], StepStatus.Complete);
      markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.ViewAll], StepStatus.Complete);
    }
    if (insuranceData.depositConfirmed) {
      !predefinedStepsStatus[StepID.WaiverGuide] && markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.WaiverGuide], StepStatus.Pending);
    }
    if (insuranceData.depositConfirmed && (predefinedStepsStatus![StepID.PurchaseInsurance] || {}).status !== StepStatus.Complete) {
      Cookies.remove('universityID');
      Cookies.remove('email');
      Cookies.remove('dob');

      Cookies.remove('planID');

      setTimeout(() => {
        markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.PurchaseInsurance], StepStatus.Complete);
        navigate(`/${UserJourneyStageId.HealthInsurance}/get-waiver-guide`);
      }, 2000);

      markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.EnterDetails], StepStatus.Complete);
      markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.ViewAll], StepStatus.Complete);
      markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.ViewRecommended], StepStatus.Complete);
    }
    if (insuranceData.invoiceURL)
      setTimeout(() => {
        markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.PayInvoice], insuranceData.invoicePaid ? StepStatus.Complete : StepStatus.ReadyForReview);
      }, 2000);
  }, [currentUser, insuranceData, predefinedStepsStatus]);

  const updateStatus = (step: PredefinedStep, currentUser: UserProfile) => {
    const wg = predefinedStepsStatus && predefinedStepsStatus[StepID.WaiverGuide] && predefinedStepsStatus[StepID.WaiverGuide].waiverGuide;
    if (insuranceData)
      if (
        (insuranceData.cardLongURL && step && step.status && ![StepStatus.Complete, StepStatus.ReadyForReview].includes(step.status)) ||
        (wg === 'automated' && insuranceData.status === 'WaiverApplicationApproved')
      ) {
        markStepsStatus(
          currentUser.id,
          [UserJourneyStageId.HealthInsurance, StepID.WaiverGuide],
          insuranceData.status === 'WaiverApplicationApproved' ? StepStatus.Complete : StepStatus.ReadyForReview,
        );
        markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.YourInsurance], StepStatus.Complete);
      }
  };

  const lockPurchaseModalData = {
    description: 'This step is locked. You need to complete "Enter your details" before you can proceed with this step.',
    ctaText: (
      <>
        Go to "Enter your details"
        <RightOutlined />
      </>
    ),
    ctaAction: () => {
      navigate(`/${UserJourneyStageId.HealthInsurance}/enter-details`);
    },
  };

  const lockModalData = {
    description: 'This step is locked. You need to complete "Purchase Insurance" before you can proceed with this step.',
    ctaText: (
      <>
        Go to "Purchase Insurance"
        <RightOutlined />
      </>
    ),
    ctaAction: () => {
      navigate(`/${UserJourneyStageId.HealthInsurance}/purchase-insurance`);
    },
  };

  if (currentUser)
    return !predefinedStepsStatus && showWelcomeScreen && !currentUser.isAnonymous ? (
      <WelcomeScreen setShowWelcomeScreen={setShowWelcomeScreen} />
    ) : (
      <div className={`${Styles.container} animate__animated animate__fadeIn`}>
        <div className={Styles.childContainer}>
          <WelcomeUserWithProgressHeading
            stageID={UserJourneyStageId.HealthInsurance}
            title="Purchase Health Insurance"
            currentUser={currentUser}
            stepCount={{ total: steps.length, left: stepsLeft || 0 }}
          />
          <Accordion
            ref={AccordionInstance}
            onExpanded={(index) => {
              if (getIndexFromRoute(pathname, steps) === index) {
                return navigate!(`/${UserJourneyStageId.HealthInsurance}`);
              }
              const route = steps[index].route;
              navigate!(`/${UserJourneyStageId.HealthInsurance}/${route}`);
            }}
            defaultExpanded={getIndexFromRoute(pathname, steps)}
            className={Styles.accordion}>
            {steps.map((step) => {
              switch (step.id) {
                case StepID.AttendWebinar:
                  return (
                    <AttendWebinarAccordionItem
                      key={step.route}
                      onCompleted={(completed) => {
                        if (calculatedStepsCompleted.includes(step.title) && !completed) {
                          setCalculatedStepsCompleted([...calculatedStepsCompleted.filter((title) => title !== step.title)]);
                          return;
                        }
                        if (!calculatedStepsCompleted.includes(step.title) && completed) {
                          setCalculatedStepsCompleted([...calculatedStepsCompleted, step.title]);
                          return;
                        }
                      }}
                      title={step.title}
                      rsvpStep={step as RsvpStep}
                    />
                  );
                case StepID.EnterDetails:
                  return (
                    <Accordion.Item lockOnComplete key={step.id} icon={'edit'} status={step.status || StepStatus.Pending} title={step.title}>
                      <EnterDetails setComparisonData={setComparisonData} university={comparisonData?.university} />
                    </Accordion.Item>
                  );

                case StepID.ViewRecommended:
                  return (
                    <Accordion.Item
                      lockOnComplete
                      key={step.id}
                      icon={'like'}
                      status={step.status || (comparisonData ? StepStatus.Pending : StepStatus.Locked)}
                      lockModal={lockPurchaseModalData}
                      title={step.title}>
                      {comparisonData && comparisonData.university && comparisonData.dob ? <ViewRecommended comparisonData={comparisonData} /> : <></>}
                    </Accordion.Item>
                  );

                case StepID.ViewAll:
                  return (
                    <Accordion.Item
                      lockOnComplete
                      key={step.id}
                      icon={'safety-certificate'}
                      status={step.status || (comparisonData && comparisonData.university && comparisonData.dob.isValid() ? StepStatus.Pending : StepStatus.Locked)}
                      lockModal={lockPurchaseModalData}
                      title={step.title}>
                      {comparisonData && comparisonData.university && comparisonData.dob.isValid() ? <ViewAllView comparisonData={comparisonData} /> : <></>}
                    </Accordion.Item>
                  );
                case StepID.PurchaseInsurance:
                  return (
                    <Accordion.Item
                      lockOnComplete
                      key={step.id}
                      icon={'file-protect'}
                      lockModal={lockPurchaseModalData}
                      status={step.status || ((comparisonData && selectedPlan) || insuranceData ? StepStatus.Pending : StepStatus.Locked)}
                      title={step.title}>
                      {plan && plan.insuranceCompany === 'GBG' ? <GBGPurchaseForm comparisonData={comparisonData} /> : <WellAwayPurchaseForm comparisonData={comparisonData} />}
                    </Accordion.Item>
                  );
                case StepID.WaiverGuide: {
                  updateStatus(step, currentUser);
                  const waiverGuide = predefinedStepsStatus && predefinedStepsStatus[StepID.WaiverGuide] && predefinedStepsStatus[StepID.WaiverGuide].waiverGuide;

                  return (
                    <Accordion.Item
                      key={step.id}
                      icon={'read'}
                      lockModal={lockModalData}
                      status={step.status || (insuranceData && insuranceData.depositConfirmed ? StepStatus.Pending : StepStatus.Locked)}
                      title={step.title}
                      showMarkAsComplete={
                        waiverGuide !== 'automated' && step.status !== StepStatus.Processing
                          ? {
                              uid: currentUser.id,
                              route: [UserJourneyStageId.HealthInsurance, step.id],
                            }
                          : undefined
                      }>
                      <WaiverGuide insuranceData={insuranceData} waiverGuide={waiverGuide} />
                    </Accordion.Item>
                  );
                }
                case StepID.PayInvoice:
                  return (
                    <Accordion.Item
                      highlighted
                      key={step.id}
                      icon={'dollar'}
                      status={step.status || (insuranceData && insuranceData.depositConfirmed && insuranceData.invoiceURL ? StepStatus.ReadyForReview : StepStatus.Locked)}
                      lockModal={lockModalData}
                      title={step.title}>
                      <PayInvoice />
                    </Accordion.Item>
                  );
                case StepID.YourInsurance:
                  updateStatus(step, currentUser);
                  return (
                    <Accordion.Item
                      highlighted
                      key={step.id}
                      icon={'folder-open'}
                      status={step.status || (insuranceData && insuranceData.depositConfirmed ? StepStatus.Pending : StepStatus.Locked)}
                      lockModal={lockModalData}
                      title={step.title}>
                      <InsuranceDocs insuranceData={insuranceData} />
                    </Accordion.Item>
                  );
                case StepID.FileInsuranceClaim:
                  return (
                    <FileInsurance
                      key={step.id}
                      step={{
                        ...step,
                        status: step.status || (insuranceData && insuranceData.depositConfirmed ? StepStatus.Pending : StepStatus.Locked),
                      }}
                      lockModal={lockModalData}
                      university={insuranceData && insuranceData.university}
                      currentUser={currentUser}
                    />
                  );
                default: {
                  return renderCustomStep(step, calculatedStepsCompleted, setCalculatedStepsCompleted, currentUser!.shownBadges![UserJourneyStageId.HealthInsurance] || []);
                }
              }
            })}
          </Accordion>
        </div>
      </div>
    );
  else return null;
};

const mapStateToProps = (state: RootState) => {
  const { steps } = state.CustomSteps;
  const { currentUser } = state.Auth;
  const allPredefinedSteps = state.Content.predefinedSteps[UserJourneyStageId.HealthInsurance];
  return {
    currentUser,
    customSteps: steps[UserJourneyStageId.HealthInsurance] || [],
    allPredefinedSteps,
  };
};

export default connect(mapStateToProps, {})(HealthInsuranceStepContainer);
