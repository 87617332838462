import { Progress } from 'antd';
import React from 'react';
import { UserJourneyStageId } from '../../config';
import { stageIcons } from '../../repos';
import { Colors } from '../../theme';
import Styles from './ProgressHeading.module.sass';

interface Props {
  title: string;
  stepCount: { total: number; left: number };
  className?: string;
  stageID: UserJourneyStageId;
}

export const ProgressHeading: React.FC<Props> = (props) => {
  const { title, stepCount, className, stageID } = props;

  return (
    <div className={className}>
      <img src={stageIcons[stageID]} alt={title}></img>
      <div className={Styles.progressTitle}>
        <div className={Styles.heading}>{title}</div>
        {stepCount.left > 0 && (
          <div className={Styles.stepsLeft}>
            {stepCount.left} step{stepCount.left > 1 ? 's' : ''} left
          </div>
        )}
        {stepCount.left === 0 && (
          <div className={Styles.stepsLeftComplete}>All steps complete!</div>
        )}
      </div>
      <Progress
        className={Styles.progressBar}
        strokeColor={Colors.Teal600}
        showInfo={false}
        status="success"
        percent={((stepCount.total - stepCount.left) * 100) / stepCount.total}
      />
    </div>
  );
};
