import { Dayjs } from 'dayjs';
import { produce } from 'immer';
import { InsuranceRequestGBGType, InsuranceRequestWellAwayType, PlanType } from '../../repos';
import { InsuranceAction, InsuranceActionTypes } from '../actions';

export interface InsuranceStateType {
  readonly selectedPlan?: PlanType;
  readonly availablePlans?: PlanType[];
  readonly formData?: Record<string, any>;
  readonly insuranceData?: InsuranceRequestGBGType | InsuranceRequestWellAwayType;
  readonly pastEndDate?: Dayjs;
}

const initialState: InsuranceStateType = {};

export const InsuranceReducer = (state = initialState, action: InsuranceAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case InsuranceActionTypes.SetSelectedPlan:
        draft.selectedPlan = action.payload;
        break;
      case InsuranceActionTypes.SetFormData:
        draft.formData = action.payload;
        break;
      case InsuranceActionTypes.SetAvailablePlans:
        draft.availablePlans = action.payload;
        break;
      case InsuranceActionTypes.SetInsuranceData:
        draft.insuranceData = action.payload;
        break;
      case InsuranceActionTypes.SetPastEndDate:
        draft.pastEndDate = action.payload;
        break;
      default:
        break;
    }
    return draft;
  });
};
