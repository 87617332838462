import React, { FC, useEffect, useState } from 'react';

import { Button, Col, DatePicker, Form, Input, Radio, Row, Select, Skeleton, Steps, Modal } from 'antd';
import { countries, InsuranceRequestGBGType, InsuranceRequestPartner, InsuranceRequestStatus, markStepsStatus, PlanType, StepStatus, University, UserRepo } from '../../repos';
import { CheckCircleFilled, CheckOutlined, DollarOutlined, IdcardOutlined, RightOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import Styles from './PurchaseForms.module.sass';
import { PhoneInput } from '../../components/PhoneInput';
import { isValidNumber } from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setAvailablePlans, setFormData, setInsuranceData, setSelectedPlan } from '../../redux';
import { UniversityInput } from '../../components';
import { Environment, FirestoreReference } from '../../utils';
import moment from 'moment';
import { calculateInsurancePremium, getPlans } from './helpers';
import { GradlyLoading } from '../../App';
import { UserJourneyStageId } from '../../config';
import { ComparisonData, StepID } from './HealthInsuranceStepContainer';
import firebase from 'firebase/compat';
import { SignUpContainer } from '../SignUp';

const { Step } = Steps;

interface Props {
  comparisonData?: ComparisonData;
}

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 20 },
};

const all_countries: Record<string, string> = {};
countries.forEach((country) => {
  all_countries[country.code] = country.name;
});

const secQuestions = [
  { label: 'In which city did you complete your graduation?', value: 'cityGraduated' },
  { label: 'Who is your favorite sports person?', value: 'favoriteSportsPerson' },
  { label: "What is your mother's maiden last name?", value: 'mothersMaidenName' },
  { label: 'What is the name of your favorite pet?', value: 'favoritePet' },
  { label: "What is your father's middle name?", value: 'fathersMiddleName' },
  { label: "What is your favorite teacher's name?", value: 'favoriteTeachersName' },
  { label: 'What is your high school mascot?', value: 'highSchoolMascot' },
  { label: "What is your mother's middle name?", value: 'mothersMiddleName' },
  { label: 'Which city were you born in?', value: 'cityBorn' },
];

export const GBGPurchaseForm: FC<Props> = (props) => {
  const { university, dob, email } = props.comparisonData || {};
  const [currentStep, setCurrentStep] = useState(0);
  const plans = useSelector((state: RootState) => state.Insurance.availablePlans) || [];
  const { insuranceData, selectedPlan, formData } = useSelector((state: RootState) => state.Insurance);
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);
  const dispatch = useDispatch();
  const [accountForm] = Form.useForm();
  const [finalPremium, setFinalPremium] = useState('0');
  const [coverageOptions, setCoverageOptions] = useState<any>([
    { label: 'Annual', value: 'annual' },
    { label: 'Graduating', value: 'graduating' },
    { label: 'Fall Graduating', value: 'fallgraduating' },
  ]);

  const [paymentString, setPaymentString] = useState('Waiting for payment...');

  const checkOutStripe = (docID: string, planName: string) => {
    if (!currentUser || !docID) return;
    setPaymentString('Redirecting you to payment page...');
    markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.PurchaseInsurance], StepStatus.Processing);
    UserRepo.getIdToken().subscribe(async (token) => {
      fetch(`${Environment.api.baseUrl}/webhooks/stripe/stripe-checkout`, {
        method: 'POST',
        body: JSON.stringify({
          planName,
          email: currentUser?.primaryEmail,
          name: currentUser?.name,
          docID,
          partner: InsuranceRequestPartner.GBG,
          redirectUri: window.location.href,
          stripeCustomerID: currentUser?.stripeCustomerID,
        }),
        headers: {
          'content-type': 'application/json',
          authorization: token,
        },
      }).then(async (response) => {
        const responseURL = await response.json();
        if (responseURL.url) {
          window.location.href = responseURL.url;
        }
      });
    });
  };

  const createEnrollment = async (formData: any) => {
    if (!currentUser || !formData) return;
    const university = formData.account?.university[0] as University;
    const plan = plans[parseInt(formData.account.insurancePlanID)] || selectedPlan;
    if (plan.insuranceCompany === 'GBG') {
      const docRef = FirestoreReference.InsuranceGBGRequests().doc();
      const period = formData.account.coveragePeriod.split(':');
      const phonePrefix = formData.personal.prefix.value || formData.personal.prefix || 'US:1';
      const phoneNumber = `+${phonePrefix.split(':')[1]}${formData.personal.phone}`;

      const reqData: InsuranceRequestGBGType = {
        purchaseStartDate: moment().format('YYYY-MM-DD'),
        graduationYear: formData.account.graduationYear,
        coveragePeriod: period[0],
        coverageStartDate: moment(period[1].split('-')[0]).format('YYYY-MM-DD'),
        coverageEndDate: moment(period[1].split('-')[1]).format('YYYY-MM-DD'),
        passportCountry: formData.personal.citizenship,
        gender: formData.personal.gender,
        maritalStatus: formData.personal.maritalStatus,
        securityQuestion1: formData.account.securityQuestion1,
        securityQuestion2: formData.account.securityQuestion2,
        securityAns1: formData.account.securityAns1,
        securityAns2: formData.account.securityAns2,
        memberId: 'NA',
        status: InsuranceRequestStatus.PesonalisedPlansReceived,
        requestID: docRef.id,
        userID: currentUser.id,
        firstName: formData.personal.firstName,
        lastName: formData.personal.lastName,
        email: formData.account.email,
        mobileNumber: phoneNumber,
        dob: formData.personal.dob.format('YYYY-MM-DD'),
        nationality: formData.personal.nationality,
        destinationCountryCode: university.gbgDestinationCountry,
        destinationStateCode: university.gbgDestinationState,
        university: university.name,
        universityID: university.id,
        studentId: formData.account.studentID,
        insurancePlan: plan.retailName,
        insurancePlanID: plan.planID,
        homeCountryCode: formData.personal.nationality,
        homeAddress1: formData.personal.homeAddress,
        destinationAddress1: formData.personal.destinationAddress,
        year: parseInt(moment().format('YYYY')),
        partner: InsuranceRequestPartner.GBG,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        numberOfMonths: 0,
      };
      if (Environment.env !== 'production') reqData.testMe = true;
      setPaymentString('Redirecting you to payment page...');
      calculateInsurancePremium(
        reqData.dob,
        reqData.universityID,
        reqData.insurancePlanID,
        moment(reqData.coverageStartDate, 'YYYY-MM-DD'),
        moment(reqData.coverageEndDate, 'YYYY-MM-DD'),
      ).then(async (data) => {
        if (data) {
          setFinalPremium(data.toFixed(2));
          reqData.finalPremium = parseFloat(data.toFixed(2));
        }
        dispatch(setInsuranceData(reqData));
        await docRef.set(reqData);
        markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.PurchaseInsurance], StepStatus.Processing);
        checkOutStripe(docRef.id, plan.retailName);
      });
    }
  };
  const { Option } = Select;

  const children: React.ReactNode[] = plans.map((p, index) => <Option key={index}>{p.retailName}</Option>);

  useEffect(() => {
    if (insuranceData) {
      calculateInsurancePremium(
        insuranceData.dob,
        insuranceData.universityID,
        insuranceData.insurancePlanID,
        moment(insuranceData.coverageStartDate, 'YYYY-MM-DD'),
        moment(insuranceData.coverageEndDate, 'YYYY-MM-DD'),
      ).then((data) => {
        if (data) setFinalPremium(data.toFixed(2));
      });
    }
  }, [insuranceData]);

  const setPlans = (plan: PlanType[]) => {
    dispatch(setAvailablePlans(plan));
  };

  useEffect(() => {
    if (insuranceData) setCurrentStep(3);
  }, [insuranceData]);

  useEffect(() => {
    accountForm.setFieldsValue(
      formData
        ? formData.account
        : {
            insurancePlanID: selectedPlan?.retailName,
            email: email,
          },
    );
  }, [currentStep]);

  const getTermLabel = (label: string, subLabel: string) => (
    <div className={Styles.termLabel}>
      {label} <div className={Styles.termSubLabel}>{subLabel}</div>
    </div>
  );

  const getLabel = (label: string, subLabel: string, required?: boolean) => {
    return (
      <div className={Styles.label}>
        {label} {required && <div className={Styles.requiredMark}>*</div>}
        <p className={Styles.subLabel}>{subLabel}</p>
      </div>
    );
  };

  useEffect(() => {
    if (!accountForm.getFieldValue('university')) return;
    const uni = accountForm.getFieldValue('university')[0] as University;
    if (uni && uni.plans) {
      getPlans(uni, setPlans);
    } else {
      setPlans([]);
    }
    if (uni) {
      const options = [];
      if (uni.annualEndDate && uni.annualStartDate) {
        options.push({
          label: getTermLabel('Annual', `${uni.annualStartDate} - ${uni.annualEndDate}`),
          value: `annual:${uni.annualStartDate} - ${uni.annualEndDate}`,
        });
      }
      if (uni.graduatingEndDate && uni.graduatingStartDate) {
        options.push({
          label: getTermLabel('Graduating', `${uni.graduatingStartDate} - ${uni.graduatingEndDate}`),
          value: `graduating:${uni.graduatingStartDate} - ${uni.graduatingEndDate}`,
        });
      }
      if (uni.fallStartDate && uni.fallEndDate) {
        options.push({
          label: getTermLabel('Fall Graduating', `${uni.fallStartDate} - ${uni.fallEndDate}`),
          value: `falmdraduating:${uni.fallStartDate} - ${uni.fallEndDate}`,
        });
      }
      setCoverageOptions(options);
    }
  }, [accountForm.getFieldValue('university')]);

  let steps = [
    {
      title: 'Personal',
      icon: <UserOutlined />,
      form: (
        <Form
          {...formItemLayout}
          initialValues={formData ? formData.personal : { dob: dob?.isValid() ? dob : null }}
          size={'large'}
          colon={false}
          className={Styles.form}
          labelWrap
          onFinish={(data) => {
            dispatch(setFormData({ ...formData, personal: data }));
            setCurrentStep(1);
          }}>
          <Row>
            <Col sm={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please enter your first name.' }]} label="First Name" name="firstName">
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item label="Middle Name" name="middleName">
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please enter your last name.' }]} label="Last Name" name="lastName">
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please enter your date of birth.' }]} label="Date of Birth" name="dob">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please select your gender.' }]} label="Gender" name="gender">
                <Select placeholder="Choose">
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                  <Option value={0}>Other</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please select your marital status.' }]} label="Marital Status" name="maritalStatus">
                <Select placeholder="Choose">
                  <Option value="Single">Single</Option>
                  <Option value="Married">Married</Option>
                  <Option value="Divorced">Divorced</Option>
                  <Option value="Widowed">Widowed</Option>
                  <Option value="Other">Other</Option>
                  <Option value="Unknown">Unknown</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item
                hasFeedback
                label="Active Phone Number"
                name={'phone'}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your phone number.',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!getFieldValue('prefix')) return Promise.reject(new Error('Please select ISD Code.'));
                      const prefix = getFieldValue('prefix').value || getFieldValue('prefix');
                      const phoneNumber = `+${prefix.split(':')[1]}${value}`;
                      if (!value || (phoneNumber && isValidNumber(phoneNumber))) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Please enter a valid phone number.'));
                    },
                  }),
                ]}>
                <PhoneInput />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={24}>
              <Form.Item required label="US Residential Address" name="destinationAddress">
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={24}>
              <Form.Item required label="Home Country Residential Address" name="homeAddress">
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={9}>
              <Form.Item
                className={Styles.noMark}
                rules={[{ required: true, message: 'Please select your nationality.' }]}
                label={getLabel('Nationality', 'The country in which you were born', true)}
                name="nationality">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())}
                  placeholder="Choose"
                  options={Object.entries(all_countries).map((c) => {
                    return { value: c[0], label: c[1] };
                  })}></Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={9}>
              <Form.Item
                className={Styles.noMark}
                rules={[{ required: true, message: 'Please select your citizenship.' }]}
                label={getLabel('Citizenship', 'The country whose passport you actively hold', true)}
                name="citizenship">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())}
                  placeholder="Choose"
                  options={Object.entries(all_countries).map((c) => {
                    return { value: c[0], label: c[1] };
                  })}></Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Next <RightOutlined />
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: 'Account',
      icon: <IdcardOutlined />,
      form: (
        <Form
          key={'account'}
          form={accountForm}
          {...formItemLayout}
          size={'large'}
          colon={false}
          className={Styles.form}
          labelWrap
          onValuesChange={(values) => {
            if (values.insurancePlanID) {
              const plan = plans[parseInt(values.insurancePlanID)];
              if (plan && plan.insuranceCompany !== InsuranceRequestPartner.GBG) {
                Modal.confirm({
                  content: 'Insurance company has changed for this plan. Do you want to continue?',
                  onOk: () => {
                    dispatch(setSelectedPlan(plan));
                  },
                });
              }
            }
          }}
          onFinish={(data) => {
            dispatch(setFormData({ ...formData, account: data }));
            createEnrollment({ ...formData, account: data });
            setCurrentStep(2);
          }}>
          <Row>
            <Col sm={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please enter your first name.' }]} label="University" name="university">
                <UniversityInput defaultSelectedUniversities={university ? [university] : []} style={{ border: '1px solid #CBD2D9', borderRadius: 4 }} multiple={false} />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item
                rules={[
                  { required: true, message: 'Please enter your first name.' },
                  { type: 'email', message: 'Please enter a valid email address.' },
                ]}
                label="Student University Email ID"
                name="email">
                <Input type="email" />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please enter your student ID.' }]} label="Student ID #" name="studentID">
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please select your year of graduation.' }]} label="Year of Graduation" name="graduationYear">
                <Select
                  placeholder="Choose"
                  options={[2022, 2023, 2024, 2025, 2026, 2027].map((y) => {
                    return { value: y, label: y };
                  })}></Select>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item rules={[{ required: true, message: 'Please select your year of graduation.' }]} label="Coverage Period" name="coveragePeriod">
                <Radio.Group disabled={!accountForm.getFieldValue('university')} className={Styles.radioGroup} options={coverageOptions}></Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                wrapperCol={{ span: 8 }}
                rules={[{ required: true, message: 'Please select the insurance plan.' }]}
                label="Choice of Insurance Plan"
                name="insurancePlanID">
                <Select disabled={!accountForm.getFieldValue('university')} placeholder="Choose">
                  {children}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please select yes if you are referred by someone or are you a part of a group',
                  },
                ]}
                label="Were you referred by someone or are you a part of a group?"
                name="partOfGroup">
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <div className={Styles.label}>
                    Security Questions
                    <p className={Styles.subLabel} style={{ color: '#52606d' }}>
                      The answers to these questions will be used to generate your GBG Insurance Portal account, where you can file claims and view other insurance usage related
                      information.
                    </p>
                  </div>
                }>
                <Row gutter={12} style={{ marginTop: 12 }}>
                  <Col sm={24} md={10}>
                    <Form.Item name="securityQuestion1">
                      <Select placeholder="Security Question 1" options={secQuestions}></Select>
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={14}>
                    <Form.Item name="securityAns1">
                      <Input placeholder="Answer to Security Question 1"></Input>
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={10}>
                    <Form.Item name="securityQuestion2">
                      <Select placeholder="Security Question 2" options={secQuestions}></Select>
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={14}>
                    <Form.Item name="securityAns2">
                      <Input placeholder="Answer to Security Question 2"></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button disabled={!formData?.personal} type="primary" htmlType="submit">
              Next <RightOutlined />
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: 'Payment',
      icon: <DollarOutlined />,
      form: (
        <Row className={Styles.payment + ' animate__animated animate__fadeIn'}>
          <Col xs={24} md={12} className={Styles.planInfo}>
            <span>{insuranceData?.insurancePlan}</span>
            {finalPremium === '0' ? <Skeleton.Input active={true} /> : <h1>$ {finalPremium}</h1>}
            <h2>
              {moment(insuranceData?.coverageStartDate, 'YYYY-MM-DD').format('MMM Do, YYYY')} - {moment(insuranceData?.coverageEndDate, 'YYYY-MM-DD').format('MMM Do, YYYY')}
            </h2>
            <ul className={Styles.liReasons}>
              <li key={1}>
                <CheckOutlined /> ${selectedPlan?.planFeatures.deductible} Annual Deductible
              </li>
              <li key={2}>
                <CheckOutlined /> {(selectedPlan?.planFeatures.coInsurance || 0) * 100}% Coinsurance
              </li>
              <li key={3}>
                <CheckOutlined /> ${selectedPlan?.planFeatures.outOfPocketMax} out of pocket maximum
              </li>
            </ul>
          </Col>
          <Col xs={24} md={12} className={Styles.payStatus}>
            {insuranceData?.depositConfirmed ? (
              <Row align="middle" className={Styles.depositConfirmed}>
                <Row justify="center">
                  <CheckCircleFilled /> <h1>Deposit Confirmed</h1>
                </Row>
              </Row>
            ) : (
              <Row align="middle" className={Styles.processingDeposit}>
                <Row justify="center">
                  {GradlyLoading({ height: '100%' }, true)}
                  <h1>{paymentString}</h1>
                  <Row align="middle" className={Styles.paymentFail}>
                    Failed to make payment?
                    <Button
                      type="link"
                      onClick={() => {
                        if (insuranceData) checkOutStripe(insuranceData?.requestID, insuranceData.insurancePlan);
                      }}>
                      Retry payment
                    </Button>
                  </Row>
                </Row>
              </Row>
            )}
          </Col>
        </Row>
      ),
    },
  ];

  if (currentUser?.isAnonymous)
    steps = [
      {
        title: 'Register',
        icon: <UserAddOutlined />,
        form: <SignUpContainer email={currentUser?.primaryEmail} />,
      },
      ...steps,
    ];

  return (
    <Row>
      <Steps current={currentStep} className={Styles.steps}>
        {steps.map((item: any, index: number) => (
          <Step
            icon={currentStep > index || insuranceData?.depositConfirmed ? <CheckCircleFilled /> : item.icon}
            key={index}
            onClick={() => {
              if (!insuranceData && !currentUser?.isAnonymous && !!(formData?.account && formData?.personal) === (index === 2)) setCurrentStep && setCurrentStep(index);
            }}
            title={item.title}
          />
        ))}
      </Steps>
      <Row className={Styles.formContainer}>{steps[currentStep] ? steps[currentStep].form : steps[2].form}</Row>
    </Row>
  );
};
