import React, { FC } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Col, Row, Steps } from 'antd';
import Styles from './IFrameAssist.module.sass';
import { Alert } from '../';
const { Step } = Steps;

export const IFrameAssist: FC<any> = (props: any) => {
  const { currentStep, steps, containerClass, setCurrentStep } = props;
  const currentStepData = steps[currentStep];
  return (
    <Row justify="center" className={Styles.stepsContainer + ` ${containerClass || ''}`}>
      {currentStepData.alert && (
        <Alert {...currentStepData.alert} style={{ marginTop: 24, ...currentStepData.style }} />
      )}
      {steps.length > 1 && (
        <Steps current={currentStep} className={Styles.steps}>
          {steps.map((item: any, index: number) => (
            <Step
              icon={currentStep > index ? <CheckCircleFilled /> : undefined}
              key={index}
              onClick={() => {
                setCurrentStep && setCurrentStep(index);
              }}
              title={`Step ${index + 1}`}
            />
          ))}
        </Steps>
      )}
      <Row wrap={false} justify="space-between" className={Styles.contentContainer}>
        <Col
          span={currentStepData.spans ? currentStepData.spans[0] : undefined}
          flex={currentStepData.flex ? currentStepData.flex[0] : undefined}>
          {currentStepData.content.left}
        </Col>
        <Col
          span={currentStepData.spans ? currentStepData.spans[1] : undefined}
          flex={currentStepData.flex ? currentStepData.flex[1] : undefined}>
          {currentStepData.content.right}
        </Col>
      </Row>
    </Row>
  );
};
