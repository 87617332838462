import moment from 'moment';
import { Moment } from 'moment';
import { PlanType, University, uStates } from '../../repos';
import { FirestoreReference } from '../../utils';

export const getPlans = (university: University, setPlans: (arg0: PlanType[]) => void) => {
  const plans = university.plans.map(async (p) => {
    const planData = (await FirestoreReference.InsurancePlan(p.planID).get()).data() as PlanType;
    if (p.premiums) {
      for (const uniPremium of p.premiums) {
        let override = false;
        for (const planPremium of planData.premiums) {
          // If minAge and maxAge are same
          if (
            uniPremium.minAge === planPremium.minAge &&
            uniPremium.maxAge === planPremium.maxAge
          ) {
            override = true;
            planPremium.retailPrice = uniPremium.retailPrice || planPremium.retailPrice;
            planPremium.retailPriceHC = uniPremium.retailPriceHC || planPremium.retailPriceHC;
            planPremium.insuranceCompanyPrice =
              uniPremium.insuranceCompanyPrice || planPremium.insuranceCompanyPrice;
            planPremium.insuranceCompanyPriceHC =
              uniPremium.insuranceCompanyPriceHC || planPremium.insuranceCompanyPriceHC;
          }
        }
        if (!override) {
          planData.premiums.push(uniPremium);
        }
      }
    }
    return { ...planData, planID: p.planID };
  });
  Promise.all(plans).then((data) => {
    setPlans(data);
  });
};

export const calPremiumWithData = (
  dob: string,
  university: University,
  planData: PlanType,
  startDate?: Moment,
  endDate?: Moment,
) => {
  const age = moment().diff(moment(dob, 'YYYY-MM-DD'), 'years');
  const destinationState = university.gbgDestinationState;
  const p = university.plans.find((p) => p.planID === planData.planID);
  if (!p) {
    return -1;
  } // TODO: Throw sentry error
  if (p.premiums) {
    for (const uniPremium of p.premiums) {
      let override = false;
      for (const planPremium of planData.premiums) {
        // If minAge and maxAge are same
        if (uniPremium.minAge === planPremium.minAge && uniPremium.maxAge === planPremium.maxAge) {
          override = true;
          planPremium.retailPrice = uniPremium.retailPrice || planPremium.retailPrice;
          planPremium.retailPriceHC = uniPremium.retailPriceHC || planPremium.retailPriceHC;
          planPremium.insuranceCompanyPrice =
            uniPremium.insuranceCompanyPrice || planPremium.insuranceCompanyPrice;
          planPremium.insuranceCompanyPriceHC =
            uniPremium.insuranceCompanyPriceHC || planPremium.insuranceCompanyPriceHC;
        }
      }
      if (!override) {
        planData.premiums.push(uniPremium);
      }
    }
  }
  const premium = planData.premiums.filter((p) => p.minAge <= age && p.maxAge >= age)[0];
  if (!premium) {
    return -1;
    // throw Error('No premium');
  }
  let annualPremiumAmount = 0;
  if (planData.insuranceCompany === 'GBG' && startDate && endDate && destinationState) {
    if (['FL', 'PA', 'IL', 'NJ', 'NY'].includes(uStates[destinationState])) {
      annualPremiumAmount = premium.insuranceCompanyPriceHC;
    } else annualPremiumAmount = premium.insuranceCompanyPrice;
    return (annualPremiumAmount * endDate.add(1, 'd').diff(startDate, 'd')) / 365;
  } else {
    annualPremiumAmount = premium.insuranceCompanyPrice;
    return annualPremiumAmount;
  }
};

export const calculateInsurancePremium = async (
  dob: string,
  universityID: string,
  planID: string,
  startDate?: Moment,
  endDate?: Moment,
) => {
  const university = (
    await FirestoreReference.Universities().doc(universityID).get()
  ).data() as University;
  const planData = (await FirestoreReference.InsurancePlan(planID).get()).data() as PlanType;
  return calPremiumWithData(dob, university, { ...planData, planID }, startDate, endDate);
};
