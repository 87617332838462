import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Accordion,
  AttendWebinarAccordionItem,
  WelcomeUserWithProgressHeading,
} from '../../components';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { RootState } from '../../redux';
import {
  CustomStep,
  markStepsStatus,
  RsvpStep,
  StagePredefinedSteps,
  StepStatus,
  University,
  UserProfile,
} from '../../repos';
import { getIndexFromRoute } from '../utils';
import Styles from './VisaStepContainer.module.sass';
import { VisaTranscriptsCard } from './VisaTranscript';
import { renderCustomStep } from '..';
import { WelcomeScreen } from './WelcomeScreen';
import { PaySEVISFee } from './PaySEVISFee';
import { DS160View } from './DS160';
import { ConsularAppointmentView } from './ConsularAppointment';
import { renderI20Form } from './I20Form';
import { useStepCalculation } from '../utils/stepCalculationHooks';
import { CompileVisaDocs } from './CompileVisaDocs';
import { PostInteview } from './PostInterview';
import { getStepStatus } from '../../utils';
import { MockInterviewCard } from './MockInterview';

interface VisaStepContainerProps extends RouteComponentProps {
  customSteps: CustomStep[];
  currentUser?: UserProfile | null;
  allPredefinedSteps: StagePredefinedSteps;
}

export enum StepID {
  I20Form = 'I20Form',
  VisaTranscripts = 'VisaTranscripts',
  PaySEVISFee = 'PaySEVISFee',
  DS160 = 'DS160',
  ConsularAppointment = 'ConsularAppointment',
  CompileDocs = 'CompileDocs',
  PostInterview = 'PostInterview',
  MockInterview = 'MockInterview',
  AttendWebinar = 'AttendWebinar',
}

const VisaStepContainer: React.FC<VisaStepContainerProps> = (props) => {
  const { customSteps, currentUser, allPredefinedSteps } = props;
  const AccordionInstance = useRef<Accordion>(null);
  const { pathname } = useLocation();
  const [showWelcomeScreen, setShowWelcomeScreen] = useState<boolean>(true);
  const {
    steps,
    stepsLeft,
    calculatedStepsCompleted,
    setCalculatedStepsCompleted,
    predefinedStepsStatus,
  } = useStepCalculation(
    Object.values(StepID),
    allPredefinedSteps,
    customSteps,
    UserJourneyStageId.VisaApplication,
    showWelcomeScreen,
    currentUser?.id,
  );

  if (currentUser)
    return !predefinedStepsStatus && showWelcomeScreen ? (
      <WelcomeScreen setShowWelcomeScreen={setShowWelcomeScreen} />
    ) : (
      <div className={Styles.container + ' animate__animated animate__fadeIn'}>
        <div className={Styles.childContainer}>
          <WelcomeUserWithProgressHeading
            stageID={UserJourneyStageId.VisaApplication}
            title="Apply for your US Visa"
            currentUser={currentUser}
            stepCount={{ total: steps.length, left: stepsLeft || 0 }}
          />
          <Accordion
            ref={AccordionInstance}
            onExpanded={(index) => {
              if (getIndexFromRoute(pathname, steps) === index) {
                return navigate!(`/${UserJourneyStageId.VisaApplication}`);
              }
              const route = steps[index].route;
              navigate!(`/${UserJourneyStageId.VisaApplication}/${route}`);
            }}
            defaultExpanded={getIndexFromRoute(pathname, steps)}
            className={Styles.accordion}>
            {steps.map((step) => {
              switch (step.id) {
                case StepID.AttendWebinar:
                  return (
                    <AttendWebinarAccordionItem
                      key={step.route}
                      onCompleted={(completed) => {
                        if (calculatedStepsCompleted.includes(step.title) && !completed) {
                          setCalculatedStepsCompleted([
                            ...calculatedStepsCompleted.filter((title) => title !== step.title),
                          ]);
                          return;
                        }
                        if (!calculatedStepsCompleted.includes(step.title) && completed) {
                          setCalculatedStepsCompleted([...calculatedStepsCompleted, step.title]);
                          return;
                        }
                      }}
                      title={step.title}
                      rsvpStep={step as RsvpStep}
                    />
                  );
                case StepID.MockInterview:
                  return MockInterviewCard(
                    step,
                    getStepStatus([UserJourneyStageId.VisaApplication, StepID.MockInterview]),
                    () => {
                      markStepsStatus(
                        currentUser.id,
                        [UserJourneyStageId.VisaApplication, StepID.MockInterview],
                        StepStatus.Complete,
                      );
                    },
                  );
                case StepID.PaySEVISFee:
                  return <PaySEVISFee key={StepID.PaySEVISFee} step={step}></PaySEVISFee>;
                case StepID.VisaTranscripts:
                  return VisaTranscriptsCard(step, currentUser.id);
                case StepID.DS160:
                  return <DS160View key={StepID.DS160} step={step} />;
                case StepID.I20Form:
                  return renderI20Form(
                    step,
                    currentUser.id,
                    predefinedStepsStatus &&
                      (predefinedStepsStatus[StepID.I20Form]?.university as University),
                  );
                case StepID.ConsularAppointment:
                  return <ConsularAppointmentView key={StepID.ConsularAppointment} step={step} />;
                case StepID.CompileDocs:
                  return CompileVisaDocs(step, currentUser);
                case StepID.PostInterview:
                  return PostInteview(
                    step,
                    currentUser,
                    predefinedStepsStatus &&
                      predefinedStepsStatus[StepID.PostInterview]?.visaStatus,
                  );
                default: {
                  return renderCustomStep(
                    step,
                    calculatedStepsCompleted,
                    setCalculatedStepsCompleted,
                    currentUser.shownBadges[UserJourneyStageId.VisaApplication] || [],
                  );
                }
              }
            })}
          </Accordion>
        </div>
      </div>
    );
  else return <> </>;
};

const mapStateToProps = (state: RootState) => {
  const { steps } = state.CustomSteps;
  const { currentUser } = state.Auth;
  const allPredefinedSteps = state.Content.predefinedSteps[UserJourneyStageId.VisaApplication];
  return {
    currentUser,
    customSteps: steps[UserJourneyStageId.VisaApplication] || [],
    allPredefinedSteps,
  };
};

export default connect(mapStateToProps, {})(VisaStepContainer);
