import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import Styles from './PlanDetailsModal.module.sass';
import { PlanType } from '../../repos';
import { FileProtectOutlined, MessageOutlined, RightOutlined } from '@ant-design/icons';
import { IntercomAPI } from '../Intercom';
import { navigate } from '@reach/router';
import { throttle } from 'lodash';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  plan: PlanType;
}

export const PlanDetailsModal: React.FC<Props> = (props) => {
  const { visible, setVisible, plan } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const sectionsRef = useRef<HTMLDivElement[]>([]);
  const linkRefs = useRef<HTMLSpanElement[]>([]);

  const scrollIntoView = (ref: any) => {
    if (ref) ref.scrollIntoView({ behavior: 'smooth' });
  };
  const onScroll = throttle((e: any) => {
    if (sectionsRef.current.length > 0) {
      const offsets = sectionsRef.current.map((ref) => (ref ? ref.offsetTop + 40 : 0));
      const currentIndex = offsets.findIndex((offset) => offset > e.target.scrollTop);
      if (currentIndex !== activeIndex) setActiveIndex(currentIndex);
    }
  }, 100);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  });

  const planContent = [
    {
      title: 'Plan Essentials',
      content: [
        {
          title: 'Annual Premium',
          description: 'In order to keep your plan active, you’ll need to pay this monthly fee. This amount does not count towards your deductible or out-of-pocket max.',
          amount: '$876.00',
        },
        {
          title: 'Individual Deductible',
          description: 'Before hitting your deductible, you’ll pay for the majority of your care. Once you’ve hit it, Oscar will begin paying for a portion of your costs.',
          amount: '$10.00',
        },
        {
          title: 'Out-of-Pocket Maximum',
          description: 'The max ensures that you’ll never pay more than this amount for care. Once hitting the max, Oscar pays for 100% of your care.',
          amount: '$120.00',
        },
      ],
    },
    {
      title: 'Doctor Visits',
      content: [
        {
          title: 'Annual Premium',
          description: 'In order to keep your plan active, you’ll need to pay this monthly fee. This amount does not count towards your deductible or out-of-pocket max.',
          amount: '$876.00',
        },
        {
          title: 'Individual Deductible',
          description: 'Before hitting your deductible, you’ll pay for the majority of your care. Once you’ve hit it, Oscar will begin paying for a portion of your costs.',
          amount: '$10.00',
        },
        {
          title: 'Out-of-Pocket Maximum',
          description: 'The max ensures that you’ll never pay more than this amount for care. Once hitting the max, Oscar pays for 100% of your care.',
          amount: '$120.00',
        },
      ],
    },
    {
      title: 'Prescriptions',
      content: [
        {
          title: 'Annual Premium',
          description: 'In order to keep your plan active, you’ll need to pay this monthly fee. This amount does not count towards your deductible or out-of-pocket max.',
          amount: '$876.00',
        },
        {
          title: 'Individual Deductible',
          description: 'Before hitting your deductible, you’ll pay for the majority of your care. Once you’ve hit it, Oscar will begin paying for a portion of your costs.',
          amount: '$10.00',
        },
        {
          title: 'Out-of-Pocket Maximum',
          description: 'The max ensures that you’ll never pay more than this amount for care. Once hitting the max, Oscar pays for 100% of your care.',
          amount: '$120.00',
        },
      ],
    },
    {
      title: 'Tests & Lab Works',
      content: [
        {
          title: 'Annual Premium',
          description: 'In order to keep your plan active, you’ll need to pay this monthly fee. This amount does not count towards your deductible or out-of-pocket max.',
          amount: '$876.00',
        },
        {
          title: 'Individual Deductible',
          description: 'Before hitting your deductible, you’ll pay for the majority of your care. Once you’ve hit it, Oscar will begin paying for a portion of your costs.',
          amount: '$10.00',
        },
        {
          title: 'Out-of-Pocket Maximum',
          description: 'The max ensures that you’ll never pay more than this amount for care. Once hitting the max, Oscar pays for 100% of your care.',
          amount: '$120.00',
        },
      ],
    },
    {
      title: 'Emergencies',
      content: [
        {
          title: 'Annual Premium',
          description: 'In order to keep your plan active, you’ll need to pay this monthly fee. This amount does not count towards your deductible or out-of-pocket max.',
          amount: '$876.00',
        },
        {
          title: 'Individual Deductible',
          description: 'Before hitting your deductible, you’ll pay for the majority of your care. Once you’ve hit it, Oscar will begin paying for a portion of your costs.',
          amount: '$10.00',
        },
        {
          title: 'Out-of-Pocket Maximum',
          description: 'The max ensures that you’ll never pay more than this amount for care. Once hitting the max, Oscar pays for 100% of your care.',
          amount: '$120.00',
        },
      ],
    },
    {
      title: 'Summary of Benefits & Coverage',
      content: [
        {
          title: 'Annual Premium',
          description: 'In order to keep your plan active, you’ll need to pay this monthly fee. This amount does not count towards your deductible or out-of-pocket max.',
          amount: '$876.00',
        },
        {
          title: 'Individual Deductible',
          description: 'Before hitting your deductible, you’ll pay for the majority of your care. Once you’ve hit it, Oscar will begin paying for a portion of your costs.',
          amount: '$10.00',
        },
        {
          title: 'Out-of-Pocket Maximum',
          description: 'The max ensures that you’ll never pay more than this amount for care. Once hitting the max, Oscar pays for 100% of your care.',
          amount: '$120.00',
        },
      ],
    },
  ];
  return (
    <Modal
      className={Styles.modal}
      visible={visible}
      centered
      closable
      maskClosable
      destroyOnClose
      footer={null}
      onCancel={() => {
        setVisible(false);
      }}
      title={
        <div className={Styles.title}>
          <FileProtectOutlined />
          {plan.retailName}
          <div className={Styles.extras}>
            <h2>Have questions about the plan?</h2>
            <Button
              className={Styles.chatButton}
              icon={<MessageOutlined />}
              onClick={() => {
                IntercomAPI.showWithMessagePreFilled('Hey! I have questions about filling insurance claim.', false);
              }}>
              {' '}
              Chat with Insurance Expert
            </Button>
            <Button
              className={Styles.extrasB2}
              type="primary"
              onClick={() => {
                navigate!(`purchase-insurance`);
              }}>
              Purchase Plan <RightOutlined />
            </Button>
          </div>
        </div>
      }>
      <Row className={Styles.container}>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 19, offset: 5 }}>
          <Row className={Styles.planHeader}>
            <Col className={Styles.leftData} md={20}>
              <h1>{plan.retailName}</h1>
            </Col>
            <Col className={Styles.rightData} md={4}>
              <p>$846</p>
            </Col>
          </Row>
        </Col>
        <Col xs={0} md={5}>
          <div className={Styles.planSideBar}>
            {planContent.map((item, index) => (
              <span
                ref={(el: HTMLSpanElement) => (linkRefs.current[index] = el)}
                onClick={() => {
                  scrollIntoView(sectionsRef.current[index]);
                }}
                className={Styles.sideTitle}
                key={index}
                aria-label={index === activeIndex ? 'active' : ''}>
                {item.title}
              </span>
            ))}
          </div>
        </Col>
        <Col xs={24} md={19}>
          <Row className={Styles.planContent}>
            {planContent.map((item, index) => (
              <div ref={(el: HTMLDivElement) => (sectionsRef.current[index] = el)} className={Styles.planItem} key={index}>
                <h1 className={Styles.planItemTitle}>{item.title}</h1>
                <Row className={Styles.planItemContent}>
                  {item.content.map((item, index) => (
                    <Row className={Styles.planItemContentItem} key={index}>
                      <Col className={Styles.leftData} span={18}>
                        <h2>{item.title}</h2>
                      </Col>
                      <Col className={Styles.rightData} span={6}>
                        <p>{item.amount}</p>
                      </Col>
                      <Col className={Styles.leftData} md={18}>
                        <p>{item.description}</p>
                      </Col>
                    </Row>
                  ))}
                </Row>
              </div>
            ))}
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};
