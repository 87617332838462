import { navigate } from '@reach/router';
import { message } from 'antd';
import firebase from 'firebase/compat';
import Cookies from 'js-cookie';
import { parsePhoneNumber } from 'libphonenumber-js';
import { identity, pickBy } from 'lodash';
import moment from 'moment';
import { forkJoin, from, of } from 'rxjs';
import { filter, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { RootEpic } from '.';
import { UserJourneyStageId } from '../../config';
import { ApplicationStage, AuthRepo, UserInformation, UserProfile, UserRepo } from '../../repos';
import { Analytics, AnalyticsEventName, catchAndReportError } from '../../utils';
import { AuthActionTypes, listenToStepsStatus, setConfirmationResult, setCurrentUser, setError, setMessage } from '../actions';
import { store } from '../store';

export const signInUsingEmailEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.SignInUsingEmail)),
    switchMap((action) => {
      const { email, password } = action.payload;
      if (!email) {
        return of(setError('Please enter a valid email'));
      }
      if (!password) {
        return of(setError('Please enter a valid password'));
      }
      return AuthRepo.signInUsingEmail(email, password).pipe(
        tap(() => Analytics.log(AnalyticsEventName.UserSignInClicked, { origin: 'email-password' })),
        mapTo(setMessage('You are now signed in')),
        tap(() => Analytics.log(AnalyticsEventName.UserSignedIn, { origin: 'email-password' })),
        catchAndReportError((error) => {
          console.error({ error });

          if (error.code === 'auth/user-not-found') {
            return of(setError('This account does not exist. Please sign up to continue.'));
          }
          if (error.code === 'auth/wrong-password') {
            return of(setError('The password you entered is incorrect.'));
          }
          return of(setError(error.message));
        }),
      );
    }),
  );
};

export const sendSignInLinkToEmail: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.SignInUsingEasyEmail)),
    switchMap((action) => {
      const { email } = action.payload;
      if (!email) {
        return of(setError('Please enter your email'));
      }
      return AuthRepo.sendSignInLink(email).pipe(
        map(() => {
          setTimeout(() => {
            window.close();
          }, 10000);
          return setMessage(`An email has been sent to ${email} with the sign in link has been sent. Please check your email`);
        }),
        catchAndReportError((error) => of(setError(error.message))),
      );
    }),
  );
};

export const verifySignInLinkEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.VerifySignInLink)),
    switchMap((action) => {
      return AuthRepo.signInUsingEmailLink().pipe(
        map(() => {
          navigate!('/');
          return setMessage('You are now signed in');
        }),
        catchAndReportError((error) => {
          message.error('This link has expired. Please retry again');
          setTimeout(() => {
            window.location.href = '/';
          }, 3000);
          return of(setError());
        }),
      );
    }),
  );
};

export const signInUsingPhoneEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.SignInUsingPhone)),
    switchMap((action) => {
      const { phone } = action.payload;
      if (!phone) {
        return of(setError('Please enter a valid phone number'));
      }
      return AuthRepo.signInUsingPhone(phone).pipe(
        tap(() => Analytics.log(AnalyticsEventName.UserSignInClicked, { origin: 'mobile-number-otp' })),
        map((confirmationResult) => setConfirmationResult(confirmationResult)),
        catchAndReportError((error) => of(setError(error.message))),
      );
    }),
  );
};

export const verifyOtpEpic: RootEpic = (action$, store) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.VerifyOtp)),
    withLatestFrom(store),
    switchMap(([action, state]) => {
      const { otp } = action.payload;
      const { confirmationResult } = state.Auth;
      if (!otp) {
        return of(setError('Please enter a valid OTP'));
      }
      if (!confirmationResult) {
        return of(setError('Something went wrong. Please refresh the page and try again'));
      }
      return AuthRepo.verifyOtp(otp, confirmationResult).pipe(
        mapTo(setMessage('You are now signed in')),
        tap(() => Analytics.log(AnalyticsEventName.UserSignInClicked, { origin: 'mobile-number-otp' })),
        catchAndReportError((error) => of(setError(error.message))),
      );
    }),
  );
};

export const resetPasswordEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.SendRecoveryEmail)),
    switchMap((action) => {
      const { email } = action.payload;
      if (!email) {
        return of(setError('Please enter a valid email'));
      }
      return AuthRepo.sendRecoveryEmail(email).pipe(
        mapTo(setMessage(`An email has been sent to ${email} with a link to reset your password. Please follow the instructions given in the email.`)),
        catchAndReportError((error) => of(setError('Could not find an account with this email'))),
      );
    }),
  );
};

export const listenToAuthStateEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.ListenToAuthState)),
    switchMap(() => {
      return AuthRepo.listenToAuthState().pipe(
        switchMap((user) => {
          const email = Cookies.get('email');
          const anonymousUserProfile: UserProfile = {
            id: 'temp',
            primaryEmail: email || '',
            primaryPhone: '',
            name: '',
            stage: ApplicationStage.Applying,
            card_funds: 0,
            flightDate: moment().add(30, 'days'),
            completedStages: [],
            isAnonymous: true,
            shownBadges: {},
            onboardingCompleted: true,
          };
          if (user && user.isAnonymous) {
            Cookies.remove('anon', { domain: '.gradly.us' });
            Cookies.remove('anon', { domain: 'localhost' });
            anonymousUserProfile.id = user.uid;
            return of(setCurrentUser(anonymousUserProfile));
          }
          if (user) {
            return UserRepo.listenToUserProfile(user.uid).pipe(
              map(setCurrentUser),
              catchAndReportError((error) => of(setError(error.message))),
            );
          } else {
            const { universityID, email, anon } = {
              universityID: Cookies.get('universityID'),
              email: Cookies.get('email'),
              anon: Cookies.get('anon'),
            };
            if (universityID && anon) {
              console.debug('Signup anonymous');
              firebase
                .auth()
                .signInAnonymously()
                .then((cred) => {
                  if (!cred || !cred.user) return of(setError('Can not sign in to user!'));
                  const anonymousUserProfile: UserProfile = {
                    id: cred.user.uid,
                    primaryEmail: email || '',
                    primaryPhone: '',
                    name: '',
                    stage: ApplicationStage.Applying,
                    card_funds: 0,
                    flightDate: moment().add(30, 'days'),
                    completedStages: [],
                    isAnonymous: true,
                    shownBadges: {},
                    onboardingCompleted: true,
                  };
                  UserRepo.updateUserProfile(cred.user.uid, {
                    ...anonymousUserProfile,
                    flightDate: undefined,
                    id: undefined,
                  });
                  console.debug('Removing anon cookie');
                  Cookies.remove('anon', { domain: '.gradly.us' });
                  Cookies.remove('anon', { domain: 'localhost' });
                  console.debug('Removed anon cookie', Cookies.get('anon'));
                  return of(setCurrentUser(anonymousUserProfile));
                });
              localStorage.setItem('redirectTo', `/${UserJourneyStageId.HealthInsurance}/purchase-insurance`);
              navigate(`/${UserJourneyStageId.HealthInsurance}/purchase-insurance`);
              return of(setCurrentUser(anonymousUserProfile));
            }
          }
          return of(setCurrentUser(null));
        }),
        catchAndReportError((error) => of(setError(error.message))),
      );
    }),
  );
};

export const signOutEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.Logout)),
    switchMap(() => {
      return AuthRepo.signOut().pipe(
        tap(() => {
          Analytics.log(AnalyticsEventName.UserLoggedOut);
          Analytics.reset();
        }),
        mapTo(setMessage('You have been logged out')),
        catchAndReportError((error) => of(setError(error.message))),
      );
    }),
  );
};

export const signUpEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.SignUp)),
    switchMap((action) => {
      const { email, isUniversityEmail, firstName, middleName, lastName, phone, isHomeCountryPhoneNumber, password, isAnonymous } = action.payload;

      if (!firstName || !lastName) {
        return of(setError('Please enter your first name and last name'));
      }
      if (!email) {
        return of(setError('Please enter a valid email'));
      }
      if (!password) {
        return of(setError('Please enter a valid password'));
      }

      if (!phone) {
        return of(setError('Please enter your phone number'));
      }

      if (!parsePhoneNumber(phone).isValid()) {
        return of(setError('Please enter a valid phone number with your country code. For e.g (+91-9898-9090-99)'));
      }

      return from(AuthRepo.signUp(email, password, isAnonymous)).pipe(
        map((credentials) => {
          if (credentials.user && isAnonymous) {
            store.dispatch(
              setCurrentUser({
                id: credentials.user.uid,
                primaryEmail: email,
                primaryPhone: '',
                name: [firstName, middleName, lastName].filter((x) => !!x).join(' '),
                stage: ApplicationStage.Applying,
                card_funds: 0,
                flightDate: moment().add(30, 'days'),
                completedStages: [],
                isAnonymous: false,
                shownBadges: {},
                onboardingCompleted: true,
              }),
            );
            store.dispatch(listenToStepsStatus());
          }
          return credentials;
        }),
        switchMap((credential) => {
          const userInformation: Partial<UserInformation> = pickBy(
            {
              firstName,
              middleName,
              lastName,
              email: pickBy(
                {
                  university: isUniversityEmail ? email : undefined,
                  personal: isUniversityEmail ? undefined : email,
                },
                identity,
              ),
              phone: pickBy(
                {
                  homeCountry: isHomeCountryPhoneNumber ? phone : undefined,
                  destinationCountry: isHomeCountryPhoneNumber ? undefined : phone,
                },
                identity,
              ),
            },
            identity,
          );
          return forkJoin([
            UserRepo.updateUserInformation(credential.user!.uid, userInformation),
            UserRepo.updateUserProfile(credential.user!.uid, {
              name: [firstName, middleName, lastName].filter((x) => !!x).join(' '),
              primaryEmail: email,
              primaryPhone: phone,
              onboardingCompleted: isAnonymous ? true : false,
              isAnonymous: firebase.firestore.FieldValue.delete(),
            }),
          ]);
        }),
        tap(() => Analytics.log(AnalyticsEventName.UserSignedUp)),
        mapTo(setMessage('You are now signed in!')),
        catchAndReportError((error) => {
          if (error.code === 'auth/email-already-in-use') {
            return of(setError('An account with this email already exists. Please sign in using your email.'));
          }
          return of(setError(error.message));
        }),
      );
    }),
  );
};
