import { RouteComponentProps, Router } from '@reach/router';
import { HomeOutlined } from '@ant-design/icons';
import { Button, Steps } from 'antd';
import { findIndex, kebabCase } from 'lodash';
import React from 'react';
import { useLocation } from 'react-use';
import { Colors } from '../../theme';

interface Step {
  title: string;
  description?: string;
  route: string;
  component: React.FC<RouteComponentProps>;
}

interface UserJourneyStepsContainerProps extends RouteComponentProps {
  steps: Step[];
}

export const UserJourneyStepContainer: React.FC<UserJourneyStepsContainerProps> = props => {
  const { steps, navigate } = props;
  const { pathname = '' } = useLocation();
  const currentStep = Math.max(
    findIndex(steps, step => {
      if (pathname.includes(step.route)) {
        return true;
      }
      return false;
    }),
    0,
  );

  return (
    <div
      style={{
        backgroundColor: Colors.GreyLighter,
        height: 'calc(100vh - 73px)',
        width: '100%',
        padding: '24px 48px',
        flexDirection: 'column',
      }}>
      <div>
        <Button icon={<HomeOutlined />}>Home</Button>
      </div>
      <div
        style={{
          backgroundColor: 'white',
          borderWidth: 1,
          borderColor: Colors.GreyDark,
          marginTop: 16,
          flexGrow: 1,
        }}>
        <div style={{ flexDirection: 'column', borderRight: '1px solid #ECECEC' }}>
          <div style={{ padding: 16, width: 270 }}>
            <div style={{ fontWeight: 500, color: Colors.GreyDarkest }}>PROGRESS</div>
            <div style={{ marginLeft: 'auto' }}>{steps.length - currentStep} steps left</div>
          </div>
          <div style={{ backgroundColor: Colors.GreyLight }}>
            <div
              style={{
                backgroundColor: Colors.Primary,
                width: `${((currentStep + 1) / steps.length) * 100}%`,
                height: 4,
              }}
            />
          </div>
          <Steps
            current={currentStep}
            onChange={current => {
              navigate!(steps[current].route);
            }}
            style={{ marginLeft: 24, marginTop: 24 }}
            direction="vertical">
            {steps.map(({ title, description }) => {
              return <Steps.Step key={title} title={title} description={description} />;
            })}
          </Steps>
        </div>
        <div style={{ padding: '16px 24px' }}>
          <Router>
            {steps.map(step => {
              return <step.component key={step.title} path={kebabCase(step.title)} />;
            })}
          </Router>
        </div>
      </div>
    </div>
  );
};
