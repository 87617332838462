import React, { FC, useEffect, useState } from 'react';

import { Button, Col, DatePicker, Form, Input, message, Modal, Radio, Row, Select, Skeleton, Steps } from 'antd';
import {
  countries,
  InsuranceRequestPartner,
  InsuranceRequestStatus,
  InsuranceRequestWellAwayType,
  markStepsStatus,
  PlanType,
  StepStatus,
  University,
  usCitiesStates,
  UserRepo,
  uStates,
} from '../../repos';
import { CheckCircleFilled, CheckOutlined, DollarOutlined, HomeOutlined, IdcardOutlined, RightOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import Styles from './PurchaseForms.module.sass';
import { PhoneInput } from '../../components/PhoneInput';
import { isValidNumber } from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setAvailablePlans, setFormData, setInsuranceData, setPastEndDate, setSelectedPlan } from '../../redux';
import { Alert, AlertType, UniversityInput } from '../../components';
import { Environment, FirestoreReference } from '../../utils';
import moment from 'moment';
import dayjs from 'dayjs';
import { calculateInsurancePremium, getPlans } from './helpers';
import { GradlyLoading } from '../../App';
import { UserJourneyStageId } from '../../config';
import { ComparisonData, StepID } from './HealthInsuranceStepContainer';
import { SignUpContainer } from '../SignUp';
import firebase from 'firebase/compat';
import { CreateGroupInput, JoinGroupInput } from './CreateGroupInput';
const { Step } = Steps;

interface Props {
  comparisonData?: ComparisonData;
}

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const all_countries: Record<string, string> = {};
countries.forEach((country) => {
  all_countries[country.code] = country.name;
});

const secQuestions = [
  { label: 'In which city did you complete your graduation?', value: 'cityGraduated' },
  { label: 'Who is your favorite sports person?', value: 'favoriteSportsPerson' },
  { label: "What is your mother's maiden last name?", value: 'mothersMaidenName' },
  { label: 'What is the name of your favorite pet?', value: 'favoritePet' },
  { label: "What is your father's middle name?", value: 'fathersMiddleName' },
  { label: "What is your favorite teacher's name?", value: 'favoriteTeachersName' },
  { label: 'What is your high school mascot?', value: 'highSchoolMascot' },
  { label: "What is your mother's middle name?", value: 'mothersMiddleName' },
  { label: 'Which city were you born in?', value: 'cityBorn' },
];

export const WellAwayPurchaseForm: FC<Props> = (props) => {
  const { university, dob, email } = props.comparisonData || {};
  const [currentStep, setCurrentStep] = useState(0);
  const plans = useSelector((state: RootState) => state.Insurance.availablePlans) || [];
  const { insuranceData, selectedPlan, formData, pastEndDate } = useSelector((state: RootState) => state.Insurance);
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [finalPremium, setFinalPremium] = useState('0');
  const [code, setCode] = useState<string>('');
  const [prefilledCode, setPrefilledCode] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const [selectedUniversity, setSelectedUniversity] = useState<University | null>(university || null);

  useEffect(() => {
    if (university && !formData) {
      form.resetFields();
    }
  }, [university]);

  useEffect(() => {
    if (currentStep > 0 && currentUser)
      FirestoreReference.GroupCodes()
        .where('memberEmails', 'array-contains', currentUser.primaryEmail)
        .get()
        .then((snapshot) => {
          if (snapshot.docs && snapshot.docs.length > 0) {
            setPrefilledCode(snapshot.docs[0].data().code);
          }
        })
        .catch((error) => {
          console.debug('gc', error);
        });
    if (currentStep === 2 && pastEndDate) {
      form.setFieldsValue({ coverageStartDate: moment(pastEndDate.add(1, 'day').toDate()) });
    }
  }, [currentUser, currentStep, pastEndDate]);

  const [paymentString, setPaymentString] = useState('Waiting for payment...');

  const checkOutStripe = (docID: string, planName: string) => {
    if (!currentUser || !docID) return;
    setPaymentString('Redirecting to deposit payment...');
    markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.PurchaseInsurance], StepStatus.Processing);
    UserRepo.getIdToken().subscribe(async (token) => {
      fetch(`${Environment.api.baseUrl}/webhooks/stripe/stripe-checkout`, {
        method: 'POST',
        body: JSON.stringify({
          planName,
          email: currentUser?.primaryEmail,
          name: currentUser?.name,
          docID,
          partner: InsuranceRequestPartner.WellAway,
          redirectUri: window.location.href,
          stripeCustomerID: currentUser?.stripeCustomerID,
        }),
        headers: {
          'content-type': 'application/json',
          authorization: token,
        },
      }).then(async (response) => {
        const responseURL = await response.json();
        if (responseURL.url) {
          window.location.href = responseURL.url;
        }
      });
    });
  };

  const createEnrollment = (formData: any) => {
    if (!currentUser || !formData) return;
    setLoading(true);
    const groupCode = formData.account.partOfGroup ? (formData.account.createGroup === 'join' ? formData.account.gCode : code) : undefined;
    const university = formData.personal1?.university[0] as University;
    if (!formData.account.partOfGroup) {
      _createEnrollment(formData);
      setLoading(false);
      setCurrentStep(3);
      return;
    }
    UserRepo.getIdToken().subscribe(async (token) => {
      fetch(`${Environment.api.baseUrl}/webhooks/groupPlan/${formData.account.gCode ? 'applyCode' : 'createCode'}`, {
        method: 'POST',
        body: JSON.stringify({
          code: groupCode.trim(),
          name: currentUser.name,
          universityID: university.id,
          memberEmails: [1, 2, 3, 4].map((i) => {
            return formData.account['rEmail' + i];
          }),
        }),
        headers: {
          'content-type': 'application/json',
          authorization: token,
        },
      })
        .then(async (response) => {
          const data = await response.json();
          if (data.code) {
            setCode(data.code);
            _createEnrollment(formData, data.code);
            setLoading(false);
            setCurrentStep(3);
          }
          if (data.error) {
            setLoading(false);
            form.setFields([
              {
                name: 'gCode',
                errors: [data.error || 'Please input valid group code'],
              },
            ]);
          }
        })
        .catch((error) => {
          message.error('Some error happened with group discount, please contact support!');
          setLoading(false);
        });
    });
  };

  const _createEnrollment = async (formData: any, groupCode?: string) => {
    if (!currentUser || !formData) return;
    const university = formData.personal1?.university[0] as University;
    const plan = plans[parseInt(formData.account.insurancePlanID)] || selectedPlan;
    if (plan.insuranceCompany === 'WellAway') {
      const docRef = FirestoreReference.InsuranceWellAwayRequests().doc();
      const period = parseInt(formData.account.coveragePeriod);
      const phonePrefix = formData.personal1.prefix.value || formData.personal1.prefix || 'US:1';
      const phoneNumber = `+${phonePrefix.split(':')[1]}${formData.personal1.phone}`;
      const reqData: InsuranceRequestWellAwayType = {
        groupCode,
        purchaseStartDate: moment().format('YYYY-MM-DD'),
        coverageStartDate: formData.account.coverageStartDate.format('YYYY-MM-DD'),
        coverageEndDate: formData.account.coverageStartDate.add(period, 'M').subtract(1, 'day').format('YYYY-MM-DD'),
        numberOfMonths: period,
        gender: formData.personal1.gender,
        maritalStatus: formData.personal1.maritalStatus,
        securityQuestion1: formData.account.securityQuestion1,
        securityQuestion2: formData.account.securityQuestion2,
        securityAns1: formData.account.securityAns1,
        securityAns2: formData.account.securityAns2,
        status: InsuranceRequestStatus.PesonalisedPlansReceived,
        requestID: docRef.id,
        userID: currentUser.id,
        firstName: formData.personal1.firstName,
        lastName: formData.personal1.lastName,
        email: formData.personal1.email,
        mobileNumber: phoneNumber,
        usMobileNumber: formData.personal1.usPhone ? '+1' + formData.personal1.usPhone : undefined,
        dob: formData.personal1.dob.format('YYYY-MM-DD'),
        nationality: formData.personal2.nationality,
        destinationAddress1: formData.personal2.destinationAddress1,
        destinationCity: formData.personal2.destinationCity,
        destinationStateCode: uStates[formData.personal2.destinationState],
        university: university.name,
        universityID: university.id,
        studentId: formData.personal1.studentID,
        insurancePlan: plan.retailName,
        insurancePlanID: plan.planID,
        homeCountryCode: formData.personal2.nationality,
        homeAddress1: formData.personal2.homeAddress1,
        homeCity: formData.personal2.homeCity,
        homeStateCode: formData.personal2.homeState,
        homePostalCode: formData.personal2.homePostalCode,
        year: parseInt(moment().format('YYYY')),
        partner: InsuranceRequestPartner.WellAway,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        destinationCountryCode: 'US',
      };
      if (Environment.env !== 'production') reqData.testMe = true;
      setPaymentString('Redirecting to deposit payment...');
      calculateInsurancePremium(
        reqData.dob,
        reqData.universityID,
        reqData.insurancePlanID,
        moment(reqData.coverageStartDate, 'YYYY-MM-DD'),
        moment(reqData.coverageEndDate, 'YYYY-MM-DD'),
      ).then(async (data) => {
        if (data) {
          setFinalPremium(((data / 12) * period).toFixed(2));
          reqData.finalPremium = parseFloat(((data / 12) * period).toFixed(2));
        }
        dispatch(setInsuranceData(reqData));
        await docRef.set(reqData);
        markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.PurchaseInsurance], StepStatus.Processing);
        checkOutStripe(docRef.id, plan.retailName);
      });
    }
  };
  const { Option } = Select;

  const children: React.ReactNode[] = plans.map((p, index) => <Option key={index}>{p.retailName}</Option>);

  useEffect(() => {
    if (insuranceData) {
      calculateInsurancePremium(insuranceData.dob, insuranceData.universityID, insuranceData.insurancePlanID).then((data) => {
        if (data) setFinalPremium(((data / 12) * insuranceData.numberOfMonths).toFixed(2));
        setCurrentStep(3);
      });
    }
  }, [insuranceData]);

  const setPlans = (plan: PlanType[]) => {
    dispatch(setAvailablePlans(plan));
  };

  const checkExistingEnrollment = async (formData: any) => {
    if (!currentUser || !formData) return;
    let docid = (formData.firstName || '') + (formData.middleName || '') + (formData.lastName || '') + formData.dob.format('M-D-YYYY');
    docid = docid.replace(/ /g, '').toLowerCase();
    FirestoreReference.WellAwayPastPolicies(docid)
      .get()
      .then((doc) => {
        const data = doc.data();
        if (doc.exists && data) {
          const endDate = dayjs(data.endDate, 'M/D/YYYY', true);
          if (endDate.isAfter(dayjs()) || endDate.startOf('d').isSame(dayjs().startOf('d'))) {
            dispatch(setPastEndDate(endDate));
          } else {
            dispatch(setPastEndDate(undefined));
          }
        } else {
          dispatch(setPastEndDate(undefined));
        }
      });
  };

  const getLabel = (label: string, subLabel: string, required?: boolean) => {
    return (
      <div className={Styles.label}>
        {label} {required && <div className={Styles.requiredMark}>*</div>}
        <p className={Styles.subLabel}>{subLabel}</p>
      </div>
    );
  };

  useEffect(() => {
    if (!form.getFieldValue('university')) return;
    const uni = form.getFieldValue('university')[0] as University;
    setSelectedUniversity(uni);
    if (uni && uni.plans) {
      getPlans(uni, setPlans);
    } else {
      setPlans([]);
    }
  }, [form.getFieldValue('university')]);

  let steps = [
    {
      title: 'Personal 1',
      icon: <UserOutlined />,
      form: (
        <Form
          {...formItemLayout}
          form={form}
          initialValues={formData ? formData.personal1 : { dob: dob?.isValid() ? dob : null, email }}
          size="large"
          colon={false}
          className={Styles.form}
          labelWrap
          onFinish={(data) => {
            checkExistingEnrollment(data);
            dispatch(setFormData({ ...formData, personal1: data }));
            setCurrentStep(1);
          }}>
          <Row gutter={[12, 0]} align="bottom">
            <Col xs={24} md={8}>
              <Form.Item
                className={Styles.noMark}
                rules={[{ required: true, message: 'Please enter your first name.' }]}
                label={getLabel('First Name', 'Indicate as it appears on the passport.', true)}
                name="firstName">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label={getLabel('Middle Name', 'Indicate as it appears on the passport.', false)} name="middleName">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                className={Styles.noMark}
                rules={[{ required: true, message: 'Please enter your last name.' }]}
                label={getLabel('Last Name', 'Indicate as it appears on the passport.', true)}
                name="lastName">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please enter your date of birth.' }]} label="Date of Birth" name="dob">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please select your gender.' }]} label="Gender" name="gender">
                <Select placeholder="Choose">
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                  <Option value={0}>Other</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please select your marital status.' }]} label="Marital Status" name="maritalStatus">
                <Select placeholder="Choose">
                  <Option value="Single">Single</Option>
                  <Option value="Married">Married</Option>
                  <Option value="Divorced">Divorced</Option>
                  <Option value="Widowed">Widowed</Option>
                  <Option value="Other">Other</Option>
                  <Option value="Unknown">Unknown</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={10}>
              <Form.Item
                className={Styles.noMark}
                hasFeedback
                label={getLabel(
                  'Home Country Phone Number',
                  "If you're an Indian national, please provide a +91 phone number that will be active during the duration of your degree. A family member's phone number works.",
                  true,
                )}
                name={'phone'}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your home phone number.',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!getFieldValue('prefix')) return Promise.reject(new Error('Please select ISD Code.'));
                      const prefix = getFieldValue('prefix').value || getFieldValue('prefix');
                      const phoneNumber = `+${prefix.split(':')[1]}${value}`;
                      if (!value || (phoneNumber && isValidNumber(phoneNumber))) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Please enter a valid phone number.'));
                    },
                  }),
                ]}>
                <PhoneInput />
              </Form.Item>
            </Col>
            <Col xs={24} md={{ span: 10, offset: 1 }}>
              <Form.Item
                hasFeedback
                label="US Phone Number"
                name={'usPhone'}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const phoneNumber = `+1${value}`;
                      if (!value || (phoneNumber && isValidNumber(phoneNumber))) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Please enter a valid phone number.'));
                    },
                  }),
                ]}>
                <Input prefix="+1" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please enter the university you are attending.' }]} label="University" name="university">
                <UniversityInput defaultSelectedUniversities={university ? [university] : []} style={{ border: '1px solid #CBD2D9', borderRadius: 4 }} multiple={false} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                rules={[
                  { required: true, message: 'Please enter your university email address.' },
                  { type: 'email', message: 'Please enter a valid email address.' },
                ]}
                label="Student University Email ID"
                name="email">
                <Input type="email" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please enter your student ID.' }]} label="Student ID #" name="studentID">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Next <RightOutlined />
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: 'Personal 2',
      icon: <HomeOutlined />,
      form: (
        <Form
          {...formItemLayout}
          form={form}
          initialValues={formData && formData.personal2 ? formData.personal2 : {}}
          size="large"
          colon={false}
          className={Styles.form}
          labelWrap
          onFinish={(data) => {
            dispatch(setFormData({ ...formData, personal2: data }));
            setCurrentStep(2);
          }}>
          <Row gutter={[12, 0]}>
            <Col xs={24} md={24}>
              <Form.Item rules={[{ required: true, message: 'Please enter your US resedential address.' }]} label="US Residential Address" name="destinationAddress1">
                <Input placeholder="Street Address" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please enter your US resedential state.' }]} name="destinationState">
                <Select
                  placeholder="State"
                  options={Object.keys(usCitiesStates).map((state) => {
                    return { label: state, value: state };
                  })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item shouldUpdate={(p, c) => p.destinationState !== c.destinationState}>
                {({ getFieldValue, resetFields }) => {
                  resetFields(['destinationCity']);
                  return (
                    <Form.Item rules={[{ required: true, message: 'Please enter your US resedential city.' }]} name="destinationCity" shouldUpdate>
                      <Select
                        placeholder="City"
                        options={(usCitiesStates[getFieldValue('destinationState')] || []).map((v) => {
                          return {
                            label: v,
                            value: v,
                          };
                        })}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please enter your US resedential Zip code.' }]} name="destinationCode">
                <Input placeholder="Zip Code" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Form.Item
                className={Styles.noMark}
                rules={[{ required: true, message: 'Please enter your home resedential address.' }]}
                label={getLabel('Home Country Residential Address', 'This would typically be the information your university has on file for you in your home country.', true)}
                name="homeAddress1">
                <Input placeholder="Street Address" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please enter your home resedential state.' }]} name="homeState">
                <Input placeholder="State" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item name="homeCity" rules={[{ required: true, message: 'Please enter your home resedential city.' }]}>
                <Input placeholder="City" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please enter your home resedential Zip code.' }]} name="homeCode">
                <Input placeholder="Zip Code" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 0]} align="bottom">
            <Col xs={24} md={8}>
              <Form.Item
                className={Styles.noMark}
                rules={[{ required: true, message: 'Please select your citizenship.' }]}
                label={getLabel('Citizenship', 'The country whose passport you actively hold. In case of dual citizenship, please list both countries.', true)}
                name="citizenship">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())}
                  placeholder="Choose"
                  options={Object.entries(all_countries).map((c) => {
                    return { value: c[0], label: c[1] };
                  })}></Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={{ span: 8, offset: 1 }}>
              <Form.Item
                className={Styles.noMark}
                rules={[{ required: true, message: 'Please select your nationality.' }]}
                label={getLabel('Nationality', 'The country in which you were born.', true)}
                name="nationality">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())}
                  placeholder="Choose"
                  options={Object.entries(all_countries).map((c) => {
                    return { value: c[0], label: c[1] };
                  })}></Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Next <RightOutlined />
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: 'Account',
      icon: <IdcardOutlined />,
      form: (
        <Form
          key={'account'}
          form={form}
          {...formItemLayout}
          initialValues={
            formData?.account
              ? formData.account
              : {
                  insurancePlanID: selectedPlan?.retailName,
                  partOfGroup: prefilledCode ? true : undefined,
                  createGroup: prefilledCode ? 'join' : undefined,
                  gCode: prefilledCode,
                }
          }
          onValuesChange={(values) => {
            if (values.partOfGroup) {
              if (!currentUser || !selectedUniversity) return;
              UserRepo.getIdToken().subscribe(async (token) => {
                fetch(`${Environment.api.baseUrl}/webhooks/groupPlan/createCode`, {
                  method: 'POST',
                  body: JSON.stringify({
                    name: currentUser.name,
                    universityID: selectedUniversity.id,
                  }),
                  headers: {
                    'content-type': 'application/json',
                    authorization: token,
                  },
                })
                  .then(async (response) => {
                    const data = await response.json();
                    if (data.code) setCode(data.code);
                    if (data.error) {
                      message.error(data.error);
                      form.setFields([
                        {
                          name: 'partOfGroup',
                          errors: [data.error || 'Group plan not available at this time.'],
                        },
                      ]);
                    }
                  })
                  .catch((error) => {
                    message.error('Some error happened with group discount, please contact support!');
                  });
              });
            }
            if (values.insurancePlanID) {
              const plan = plans[parseInt(values.insurancePlanID)];
              if (plan && plan.insuranceCompany !== InsuranceRequestPartner.WellAway) {
                Modal.confirm({
                  content: 'Insurance company has changed for this plan. Do you want to continue?',
                  onOk: () => {
                    dispatch(setSelectedPlan(plan));
                  },
                });
              }
            }
          }}
          size="large"
          colon={false}
          className={Styles.form}
          labelWrap
          onFinish={(data) => {
            dispatch(setFormData({ ...formData, account: data }));
            createEnrollment({ ...formData, account: data });
          }}>
          <Row gutter={[12, 0]}>
            {pastEndDate && (
              <Alert
                style={{ marginBottom: 24, borderRadius: 4 }}
                message={`Your current Wellaway plan expires on ${moment(pastEndDate.toDate()).format('MMM Do')}. Your new plan will start from ${moment(pastEndDate.toDate())
                  .add(1, 'd')
                  ?.format('MMM Do')}. `}
                type={AlertType.Info}></Alert>
            )}
            <Col xs={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please select the insurance plan.' }]} label="Choice of Insurance Plan" name="insurancePlanID">
                <Select disabled={!selectedUniversity} placeholder="Choose">
                  {children}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please select your coverage start date.' }]} label="Coverage Start Date" name="coverageStartDate">
                <DatePicker
                  disabled={!!pastEndDate}
                  style={{ width: '100%' }}
                  disabledDate={(date) => {
                    if (pastEndDate) {
                      return date.isBefore(moment(pastEndDate.toDate()).add(1, 'days'));
                    }
                    return date.isBefore(moment());
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item rules={[{ required: true, message: 'Please select your coverage start date.' }]} label="Coverage Period (in months)" name="coveragePeriod">
                <Select
                  options={[4, 5, 6, 7, 8, 9, 10, 11, 12].map((m) => {
                    return { key: m, value: m, label: `${m} months` };
                  })}></Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please select yes if you are referred by someone or are you a part of a group',
                  },
                ]}
                dependencies={['createGroup']}
                label="Were you referred by someone or are you a part of a group?"
                name="partOfGroup">
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item style={{ marginTop: -16, display: 'block' }} shouldUpdate={(p, c) => p.partOfGroup !== c.partOfGroup}>
              {({ getFieldValue, resetFields }) => {
                return (
                  getFieldValue('partOfGroup') && (
                    <Form.Item
                      style={{ paddingLeft: 6, marginRight: 200 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please select Create a new group if you want to create a new group, otherwise select Join an existing group.',
                        },
                      ]}
                      shouldUpdate
                      name="createGroup">
                      <Radio.Group>
                        <Radio value={'new'}>Create a new group</Radio>
                        <Radio value={'join'}>Join an existing group</Radio>
                      </Radio.Group>
                    </Form.Item>
                  )
                );
              }}
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please select one choice',
                },
              ]}
              style={{ marginTop: -16 }}
              shouldUpdate={(p, c) => p.partOfGroup !== c.partOfGroup || p.createGroup !== c.createGroup}>
              {({ getFieldValue, resetFields }) => {
                return (
                  getFieldValue('partOfGroup') && (getFieldValue('createGroup') === 'new' ? <CreateGroupInput code={code} /> : getFieldValue('createGroup') && <JoinGroupInput />)
                );
              }}
            </Form.Item>
            <Col>
              <Form.Item
                label={
                  <div className={Styles.label}>
                    Security Questions
                    <p className={Styles.subLabel} style={{ color: '#52606d' }}>
                      The answers to these questions will be used to generate your WellAway Insurance Portal account, where you can file claims and view other insurance usage
                      related information.
                    </p>
                  </div>
                }>
                <Row gutter={12} style={{ marginTop: 12 }}>
                  <Col xs={24} md={10}>
                    <Form.Item name="securityQuestion1">
                      <Select placeholder="Security Question 1" options={secQuestions}></Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={14}>
                    <Form.Item name="securityAns1">
                      <Input placeholder="Answer to Security Question 1"></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={10}>
                    <Form.Item name="securityQuestion2">
                      <Select placeholder="Security Question 2" options={secQuestions}></Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={14}>
                    <Form.Item name="securityAns2">
                      <Input placeholder="Answer to Security Question 2"></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button disabled={!formData?.personal2} loading={loading} type="primary" htmlType="submit">
              Next <RightOutlined />
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: 'Payment',
      icon: <DollarOutlined />,
      form: (
        <Row className={Styles.payment + ' animate__animated animate__fadeIn'}>
          <Col xs={24} md={12} className={Styles.planInfo}>
            <span>{insuranceData?.insurancePlan}</span>
            {finalPremium === '0' ? <Skeleton.Input active={true} /> : <h1>$ {finalPremium}</h1>}
            <h2>
              {moment(insuranceData?.coverageStartDate, 'YYYY-MM-DD').format('MMM Do, YYYY')} - {moment(insuranceData?.coverageEndDate, 'YYYY-MM-DD').format('MMM Do, YYYY')}
            </h2>
            <ul className={Styles.liReasons}>
              <li key={1}>
                <CheckOutlined /> ${selectedPlan?.planFeatures.deductible} Annual Deductible
              </li>
              <li key={2}>
                <CheckOutlined /> {(selectedPlan?.planFeatures.coInsurance || 0) * 100}% Coinsurance
              </li>
              <li key={3}>
                <CheckOutlined /> ${selectedPlan?.planFeatures.outOfPocketMax} out of pocket maximum
              </li>
            </ul>
          </Col>
          <Col xs={24} md={12} className={Styles.payStatus}>
            {insuranceData?.depositConfirmed ? (
              <Row align="middle" className={Styles.depositConfirmed}>
                <Row justify="center">
                  <CheckCircleFilled /> <h1>Deposit Confirmed</h1>
                </Row>
              </Row>
            ) : (
              <Row align="middle" className={Styles.processingDeposit}>
                <Row justify="center">
                  {GradlyLoading({ height: '100%' }, true)}
                  <h1>{paymentString}</h1>
                  <Row align="middle" className={Styles.paymentFail}>
                    Failed to make payment?
                    <Button
                      type="link"
                      onClick={() => {
                        if (insuranceData) checkOutStripe(insuranceData?.requestID, insuranceData.insurancePlan);
                      }}>
                      Retry payment
                    </Button>
                  </Row>
                </Row>
              </Row>
            )}
          </Col>
        </Row>
      ),
    },
  ];

  if (currentUser?.isAnonymous)
    steps = [
      {
        title: 'Register',
        icon: <UserAddOutlined />,
        form: <SignUpContainer email={currentUser?.primaryEmail} />,
      },
      ...steps,
    ];

  return (
    <Row>
      <Steps current={currentStep} className={Styles.steps}>
        {steps.map((item: any, index: number) => (
          <Step
            icon={currentStep > index || insuranceData?.depositConfirmed ? <CheckCircleFilled /> : item.icon}
            key={index}
            onClick={() => {
              if (!insuranceData && !currentUser?.isAnonymous && !!(formData?.account && formData?.personal1) === (index === 3)) setCurrentStep && setCurrentStep(index);
            }}
            title={item.title}
          />
        ))}
      </Steps>
      <Row className={Styles.formContainer}>{steps[currentStep] ? steps[currentStep].form : steps[2].form}</Row>
    </Row>
  );
};
