import { InputNumber, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

interface Props {
  defaultValue?: { quant: number; verbal: number; writing: number };
  onChange: (score?: { quant: number; verbal: number; writing: number }) => void;
  disabled?: boolean;
}

export const GREInput: React.FC<Props> = props => {
  const { onChange, defaultValue, disabled } = props;

  const [quant = (defaultValue || {}).quant, setQuant] = useState<number>();
  const [verbal = (defaultValue || {}).verbal, setVerbal] = useState<number>();
  const [analytical = (defaultValue || {}).writing, setAnalytical] = useState<number>();
  const [
    quantError = defaultValue ? defaultValue.quant > 170 || defaultValue.quant < 130 : false,
    setQuantError,
  ] = useState<boolean>();
  const [
    verbalError = defaultValue ? defaultValue.verbal > 170 || defaultValue.verbal < 130 : false,
    setVerbalError,
  ] = useState<boolean>();
  const [
    analyticalError = defaultValue ? defaultValue.writing > 6 || defaultValue.writing < 0 : false,
    setAnalyticalError,
  ] = useState<boolean>();

  const validScores = [quant, verbal, analytical].filter(x => !!x && x !== 0);

  useEffect(() => {
    onChange({ quant: quant!, verbal: verbal!, writing: analytical! });
  }, [quant, verbal, analytical]);

  return (
    <div style={{ flexWrap: 'wrap' }}>
      <div style={{ flexDirection: 'column' }}>
        <div>Quantitative</div>
        <InputNumber
          disabled={disabled}
          onChange={score => {
            setQuant(score);
            score && (score < 130 || score > 170) ? setQuantError(true) : setQuantError(undefined);
          }}
          value={quant}
          style={{ marginTop: 6 }}
          placeholder={'--'}
        />
      </div>
      <div style={{ flexDirection: 'column', marginLeft: 16 }}>
        <div>Verbal</div>
        <InputNumber
          disabled={disabled}
          onChange={score => {
            setVerbal(score);
            score && (score < 130 || score > 170)
              ? setVerbalError(true)
              : setVerbalError(undefined);
          }}
          value={verbal}
          style={{ marginTop: 6 }}
          placeholder={'--'}
        />
      </div>
      <div style={{ flexDirection: 'column', marginLeft: 16 }}>
        <div>AWA</div>
        <InputNumber
          disabled={disabled}
          onChange={score => {
            setAnalytical(score);
            score && (score < 0 || score > 6)
              ? setAnalyticalError(true)
              : setAnalyticalError(undefined);
          }}
          value={analytical}
          style={{ marginTop: 6 }}
          placeholder={'--'}
        />
      </div>
      {((validScores.length < 3 && validScores.length > 0) ||
        quantError ||
        verbalError ||
        analyticalError) && (
        <Typography.Text style={{ width: '100%', marginTop: 8 }} type="danger">
          Please enter valid scores
        </Typography.Text>
      )}
    </div>
  );
};
