import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { action, ActionType } from 'typesafe-actions';
import { UserProfile } from '../../repos/User/User';

export enum AuthActionTypes {
  SignInUsingEmail = 'Auth/SignInUsingEmail',
  SignInUsingEasyEmail = 'Auth/SignInUsingEasyEmail',
  VerifySignInLink = 'Auth/VerifySignInLink',
  SignInUsingPhone = 'Auth/SignInUsingPhone',
  SignUp = 'Auth/SignUp',
  Logout = 'Auth/Logout',
  SetCurrentUser = 'Auth/SetCurrentUser',
  SetConfirmationResult = 'Auth/SetConfirmationResult',
  VerifyOtp = 'Auth/VerifyOtp',
  ListenToAuthState = 'Auth/ListenToAuthState',
  SendRecoveryEmail = 'Auth/SendRecoveryEmail',
}

export const signInUsingEmail = (email?: string, password?: string) =>
  action(AuthActionTypes.SignInUsingEmail, { email, password });

export const signInUsingEasyEmail = (email?: string) =>
  action(AuthActionTypes.SignInUsingEasyEmail, { email });

export const verifySignInLink = (redirectUri?: string) =>
  action(AuthActionTypes.VerifySignInLink, { redirectUri });

export const signInUsingPhone = (phone?: string) =>
  action(AuthActionTypes.SignInUsingPhone, { phone });

export const signUp = (
  isAnonymous: boolean,
  otp?: string,
  firstName?: string,
  middleName?: string,
  lastName?: string,
  email?: string,
  isUniversityEmail?: boolean,
  phone?: string,
  isHomeCountryPhoneNumber?: boolean,
  password?: string,
) =>
  action(AuthActionTypes.SignUp, {
    otp,
    firstName,
    middleName,
    lastName,
    isUniversityEmail,
    email,
    phone,
    isHomeCountryPhoneNumber,
    password,
    isAnonymous,
  });

export const sendRecoveryEmail = (email?: string) =>
  action(AuthActionTypes.SendRecoveryEmail, { email });

export const logout = () => action(AuthActionTypes.Logout);

export const listenToAuthState = () => action(AuthActionTypes.ListenToAuthState);

export const setCurrentUser = (currentUser: UserProfile | null) =>
  action(AuthActionTypes.SetCurrentUser, { currentUser });

export const setConfirmationResult = (confirmationResult?: firebase.auth.ConfirmationResult) =>
  action(AuthActionTypes.SetConfirmationResult, { confirmationResult });

export const verifyOtp = (otp?: string) => action(AuthActionTypes.VerifyOtp, { otp });

const authActions = {
  signInUsingEmail,
  signInUsingEasyEmail,
  verifySignInLink,
  signInUsingPhone,
  signUp,
  sendRecoveryEmail,
  logout,
  listenToAuthState,
  setCurrentUser,
  setConfirmationResult,
  verifyOtp,
};

export type AuthAction = ActionType<typeof authActions>;
