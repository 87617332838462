import { MessageOutlined } from '@ant-design/icons';
import { faFileArrowDown } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownToBracket } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { boxClip, circleProfileVisa } from '../../assets';
import { IntercomAPI } from '../../components';
import { RootState } from '../../redux';
import { InsuranceRequestGBGType, InsuranceRequestWellAwayType } from '../../repos';
import { downloadFile } from '../../utils';

import Styles from './InsuranceDocs.module.sass';

interface InsuranceDocsProps {
  insuranceData: InsuranceRequestGBGType | InsuranceRequestWellAwayType | undefined;
  hideTitle?: boolean;
}

export const InsuranceDocs: React.FC<InsuranceDocsProps> = (props) => {
  const { insuranceData, hideTitle } = props;
  const selectedPlan = useSelector((state: RootState) => state.Insurance.selectedPlan);
  return (
    <Row align="top" className={Styles.container}>
      {hideTitle ? null : (
        <h1>
          <FontAwesomeIcon className={Styles.downloadIcon} icon={faArrowDownToBracket} /> Download
          your insurance documents
        </h1>
      )}
      <Row justify="center" className={Styles.cardsContainer}>
        <Col className={Styles.doc} onClick={() => { downloadFile(insuranceData?.cardLongURL || '', 'ID card.pdf') }}>
          <span>Insurance ID Card</span>
          <img src={circleProfileVisa} alt="ID Card"></img>
        </Col>
        <Col
          onClick={() => {
            window.open(selectedPlan?.brochureURL);
          }}
          className={Styles.doc}>
          <span>Plan Brochure</span>
          <img src={boxClip} alt="Brochure"></img>
        </Col>
      </Row>

      {!hideTitle && <Col span={24} className={Styles.helpButtons}>
        <span>Need help for your insurance documents?</span>
        <Button
          type="primary"
          onClick={() => {
            IntercomAPI.showWithMessagePreFilled(
              `Hey! I have question about my Insurance documents`,
              false,
            );
          }}>
          <MessageOutlined />
          Chat with an Insurance Expert
        </Button>
      </Col>}
    </Row>
  );
};
