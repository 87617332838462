import * as Sentry from '@sentry/browser';
import { notification } from 'antd';
import React, { CSSProperties, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Intercom, Navbar } from './components';
import { HomeContainer, OnboardingContainer, PasswordlessContainer, PhoneSignInContainer, ResetPasswordContainer, SignInContainer } from './containers';
import { SignUpContainer } from './containers/SignUp';
import { listenToAuthState, listenToPredefinedSteps, listenToStepsStatus, RootState, setNotification, verifySignInLink } from './redux';
import { Notification, PredefinedSteps, UserProfile } from './repos';
import { Analytics, Environment, FirestoreReference } from './utils';
import Cohere from 'cohere-js';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import firebase from 'firebase/compat';
import { RouteComponentProps, Router, Redirect, useNavigate, navigate } from '@reach/router';
import { Colors } from './theme';
import Styles from './App.module.sass';

export enum Routes {
  Home = '/',
  AuthActions = 'auth-actions',
  PhoneSignIn = 'phone-sign-in',
  PasswordlessSignIn = 'easy-login',
  SignUp = 'sign-up',
  ResetPassword = 'reset-password',
  ResetPasswordWithCode = 'reset-password/:code',
  Loans = 'loans',
  Insurance = 'health-insurance',
}

export const GradlyLoading = (style: CSSProperties, noText?: boolean) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        color: Colors.Primary700,
        ...style,
      }}>
      <LoadingOutlined style={{ fontSize: 48 }} spin />
      {noText ? null : <div style={{ marginTop: 12 }}>Loading</div>}
    </div>
  );
};

interface AppProps {
  currentUser?: UserProfile | null;
  predefinedSteps: PredefinedSteps;
  error?: string;
  message?: string;
  listenToAuthState: typeof listenToAuthState;
  listenToPredefinedSteps: typeof listenToPredefinedSteps;
}
type AuthActionsProps = RouteComponentProps;
const AuthActions: React.FC<AuthActionsProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const queryDict: Record<string, string> = {};
    props.location?.search
      .substring(1)
      .split('&')
      .forEach(function (item) {
        queryDict[item.split('=')[0]] = item.split('=')[1];
      });
    switch (queryDict.mode) {
      case 'resetPassword':
        navigate(`/reset-password/${queryDict.oobCode}`);
        break;
      case 'signIn':
        dispatch(verifySignInLink(window.location.href));
        break;
      default:
        navigate('/' + Routes.Insurance);
        break;
    }
  }, [navigate, props]);
  return <GradlyLoading />;
};

const App: React.FC<AppProps> = (props) => {
  const dispatch = useDispatch();
  const notf = useSelector((state: RootState) => state.Common.notification);
  const stepsStatus = useSelector((state: RootState) => state.Content.stepsStatus);
  const { currentUser, listenToAuthState, listenToPredefinedSteps, predefinedSteps } = props;

  useEffect(() => {
    listenToAuthState();
  }, [listenToAuthState]);

  function hideCohere() {
    Cohere.widget('hide');
  }

  async function handleNotifications(change: firebase.firestore.DocumentChange<firebase.firestore.DocumentData>) {
    const data = change.doc.data();
    const notification: Notification = {
      key: change.doc.id,
      type: data.type,
      title: data.title,
      body: data.body,
      showOnce: data.showOnce,
      endTime: moment(data.endTime.toDate()),
    };
    if (change.type === 'added') {
      if (!(notification.showOnce && (await FirestoreReference.Notifications().doc(change.doc.id).collection('shown').doc(currentUser?.id).get()).exists)) {
        dispatch(setNotification(notification));
        if (notification.showOnce) FirestoreReference.Notifications().doc(change.doc.id).collection('shown').doc(currentUser?.id).set({ shown: true });
      }
    }
    if (change.type === 'modified') {
      dispatch(setNotification(notification));
    }
  }

  async function listenToNotifications() {
    if (!currentUser) return;
    FirestoreReference.Notifications()
      .where('endTime', '>', moment().toDate())
      .where('userID', '==', 'all')
      .onSnapshot(
        (snapshot) => {
          snapshot.docChanges().forEach(async (change) => {
            handleNotifications(change);
          });
        },
        (error) => {
          console.debug('Error getting Notifications all: ', error);
        },
      );
    FirestoreReference.Notifications()
      .where('endTime', '>', moment().toDate())
      .where('userID', '==', currentUser?.id)
      .onSnapshot(
        (snapshot) => {
          snapshot.docChanges().forEach(async (change) => {
            handleNotifications(change);
          });
        },
        (error) => {
          console.debug('Error getting Notifications: ', error);
        },
      );
  }

  useEffect(() => {
    if (notf) {
      const data = {
        message: notf.title,
        description: notf.body,
      };
      switch (notf.type) {
        case 'Success':
          notification.success(data);
          break;
        case 'Warning':
          notification.warning(data);
          break;
        case 'Danger':
          notification.error(data);
          break;
        case 'Info':
          notification.info(data);
          break;
        default:
          notification.info(data);
          break;
      }
    }
  }, [notf]);

  useEffect(() => {
    if (currentUser) {
      listenToPredefinedSteps();
      const { id, ...userProfile } = currentUser;
      Cohere.init(Environment.cohereKey);
      Cohere.widget('hide');
      Cohere.identify(id, { displayName: userProfile.name, email: userProfile.primaryEmail });
      Cohere.addCallStatusListener(hideCohere);
      listenToNotifications();
      dispatch(listenToStepsStatus());
      if (localStorage.getItem('redirectTo')) {
        navigate(localStorage.getItem('redirectTo') || `/${Routes.Home}`);
        localStorage.removeItem('redirectTo');
      }
      if (process.env.REACT_APP_NO_NAV) {
        navigate(`/${Routes.Insurance}`);
      }
    }
    return () => {
      Cohere.removeCallStatusListener(hideCohere);
    };
  }, [currentUser, listenToPredefinedSteps]);

  if (currentUser === null) {
    return (
      <div style={{ width: '100%' }}>
        <Intercom appID={Environment.intercom.appId} />
        <Router style={{ width: '100%' }}>
          <SignInContainer path={Routes.Home} />
          <AuthActions path={Routes.AuthActions} />
          <PhoneSignInContainer path={Routes.PhoneSignIn} />
          <SignUpContainer path={Routes.SignUp} />
          <ResetPasswordContainer path={Routes.ResetPassword} />
          <ResetPasswordContainer path={Routes.ResetPasswordWithCode} />
          <PasswordlessContainer path={Routes.PasswordlessSignIn} />
          <Redirect default noThrow from="/*" to={Routes.Home} />
        </Router>
      </div>
    );
  }

  if (currentUser === undefined || Object.keys(predefinedSteps).length === 0 || (currentUser && stepsStatus.loading)) {
    return <GradlyLoading />;
  }

  // Set current user identifier in analytics
  const { id, ...userProfile } = currentUser;

  Analytics.identify(id, userProfile);
  Sentry.setUser({
    id: currentUser.id,
    email: currentUser.primaryEmail,
    username: currentUser.name,
  });

  const intercomUser = {
    user_id: currentUser.id,
    email: currentUser.primaryEmail,
    name: currentUser.name,
  };

  // if (!currentUser.onboardingCompleted) {
  //   return (
  //     <div style={{ flexDirection: 'column' }}>
  //       <Intercom appID={Environment.intercom.appId} {...intercomUser} />
  //       <Navbar />
  //       <OnboardingContainer />
  //     </div>
  //   );
  // }

  return (
    <div style={{ flexDirection: 'column', minWidth: 300, overflowX: 'auto' }}>
      <Intercom appID={Environment.intercom.appId} {...intercomUser} />
      <Navbar />
      <Router className={Styles.stepsContainer}>
        <HomeContainer path={Routes.Insurance} default />
      </Router>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { currentUser } = state.Auth;

  const { predefinedSteps } = state.Content;
  return { currentUser, predefinedSteps };
};

export default connect(mapStateToProps, {
  listenToAuthState,
  listenToPredefinedSteps,
})(App);
