import { MessageOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { welcomePicture } from '../../assets';
import { UserProfile } from '../../repos';
import { useMobile } from '../../services';
import { IntercomAPI } from '../Intercom';
import Styles from './WelcomeUser.module.sass';

interface Props {
  currentUser?: UserProfile | null;
  className?: string;
}

export const WelcomeUser: React.FC<Props> = (props) => {
  const { currentUser, className } = props;
  const isMobile = useMobile()
  if (isMobile) {
    return <div className={Styles.mobileHelp}>Have questions?<Button
      className={Styles.chatButton}
      onClick={() => IntercomAPI.show()}
      icon={<MessageOutlined />}
      type="primary"> Chat with us
    </Button> </div>
  }

  return (
    <div className={className}>
      <div className={Styles.messageBox}>
        <div className={Styles.title}>Rishabh from Gradly</div>
        <div className={Styles.message}>
          {currentUser &&
            `Hi, ${currentUser.name.split(' ')[0]
            }! I’m Rishabh from Gradly. I’m here to help you with anything you need during your immigration process.`}
        </div>
        <Button
          className={Styles.chatButton}
          onClick={() => IntercomAPI.show()}
          type="primary"
          size="small">
          Chat with Rishabh
        </Button>
      </div>
      <img style={{ width: 183 }} src={welcomePicture} alt="Rishabh" />
    </div>
  );
};
