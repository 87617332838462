import algoliasearch from 'algoliasearch';
import { Environment } from '../../utils';

const ALGOLIA_ID = Environment.algolia.appId;
const ALGOLIA_ADMIN_KEY = Environment.algolia.apiKey;

export const algoliaClient = algoliasearch(ALGOLIA_ID, ALGOLIA_ADMIN_KEY);

export const AlgoliaIndexName = {
  KnowledgeBase: `${Environment.algolia.environment}_knowledgeBase`,
  FeedItems: `${Environment.algolia.environment}_feedItems`,
  Universities: `${
    Environment.algolia.environment === 'production' ? 'universities' : 'staging_universities'
  }`,
  VisaTranscripts: `visa-transcripts`,
};
