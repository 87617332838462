import { RouteComponentProps, Router } from '@reach/router';
import firebase from 'firebase/compat';
import { kebabCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  ApartmentsStepContainer,
  FinalizeUniversityStepContainer,
  HealthInsuranceStepContainer,
  PhoneConnectionStepContainer,
  SopReviewStepContainer,
  USBankAccountsStepContainer,
  UserJourneyStepContainer,
  VaccinesStepContainer,
  VisaStepContainer,
  USCreditCardStepContainer,
} from '..';
import { reportError } from '../../utils';
import { AttendWebinarAccordionItem, Accordion, CustomStepRenderer } from '../../components';
import { UserJourneyRoutes, UserJourneyStages } from '../../config/userJourneyMap';
import {
  listenToContentItems,
  listenToCustomSteps,
  listenToWebinarRegistrations,
  listenToStepsStatus,
  RootState,
} from '../../redux';
import { CustomStep, StepType, RsvpStep } from '../../repos';
import { FirestoreReference } from './../../utils/FirestoreDocumentReference';
import { ForexStepContainer } from '../ForexStep';
import { LoanStepContainer } from '../LoanStep';
import { HomePage } from './HomePage';
import { message } from 'antd';

interface Props extends RouteComponentProps {
  listenToWebinarRegistrations: typeof listenToWebinarRegistrations;
  listenToContentItems: typeof listenToContentItems;
  listenToCustomSteps: typeof listenToCustomSteps;
  listenToStepsStatus: typeof listenToStepsStatus;
}

const HomeContainer: React.FC<Props> = (props: Props) => {
  const {
    listenToWebinarRegistrations,
    listenToContentItems,
    listenToCustomSteps,
    listenToStepsStatus,
  } = props;
  const steps: { title: string; description: string }[] = [];
  const { successMessage, error } = useSelector((state: RootState) => state.Common);

  useEffect(() => {
    listenToWebinarRegistrations();
    listenToContentItems();
    listenToCustomSteps();
    listenToStepsStatus();
  }, [
    listenToWebinarRegistrations,
    listenToContentItems,
    listenToCustomSteps,
    listenToStepsStatus,
  ]);

  useEffect(() => {
    if (error) {
      message.error(error);
      return;
    }
    if (successMessage) {
      message.success(successMessage);
      return;
    }
  }, [error, successMessage]);

  const UserRoutes = [['home', '']];

  Object.entries(UserJourneyStages).forEach(([stageId, stage]) => {
    UserRoutes.push([stageId, stage.route]);
  });

  return (
    <Router style={{ width: '100%' }}>
      {UserRoutes.map(([stageId, route]) => {
        switch (route) {
          case '':
            return <HomePage key={stageId} default />;
          case UserJourneyRoutes.SopReview:
            return <SopReviewStepContainer key={stageId} path={`${route}/*`} />;
          case UserJourneyRoutes.VisaApplication:
            return <VisaStepContainer key={stageId} path={`${route}/*`} />;
          case UserJourneyRoutes.HealthInsurance:
            return <HealthInsuranceStepContainer key={stageId} path={`${route}/*`} />;
          case UserJourneyRoutes.Loans:
            return <LoanStepContainer key={stageId} path={`${route}/*`} />;
          case UserJourneyRoutes.Forex:
            return <ForexStepContainer key={stageId} path={`${route}/*`} />;
          case UserJourneyRoutes.FinalizeUniversity:
            return <FinalizeUniversityStepContainer key={stageId} path={`${route}/*`} />;
          case UserJourneyRoutes.Vaccines:
            return <VaccinesStepContainer key={stageId} path={`${route}/*`} />;
          case UserJourneyRoutes.PhoneConnection:
            return <PhoneConnectionStepContainer key={stageId} path={`${route}/*`} />;
          case UserJourneyRoutes.Apartments:
            return <ApartmentsStepContainer key={stageId} path={`${route}/*`} />;
          case UserJourneyRoutes.USBankAccounts:
            return <USBankAccountsStepContainer key={stageId} path={`${route}/*`} />;
          case UserJourneyRoutes.USCreditCard:
            return <USCreditCardStepContainer key={stageId} path={`${route}/*`} />;
          default: {
            const component: React.FC<RouteComponentProps> = () => {
              return <div></div>;
            };
            const stepsMore = steps.map((step) => ({
              component,
              route: kebabCase(step.title),
              ...step,
            }));
            return <UserJourneyStepContainer key={stageId} path={`${route}/*`} steps={stepsMore} />;
          }
        }
      })}
    </Router>
  );
};

function setShownBadge(userId: string, route: any, userJourneyStageId: any, badges: string[]) {
  try {
    if (!badges.includes(route))
      FirestoreReference.UserProfiles()
        .doc(userId)
        .set(
          {
            shownBadges: {
              [userJourneyStageId]: firebase.firestore.FieldValue.arrayUnion(route),
            },
          },
          { merge: true },
        );
  } catch (e) {
    reportError(e);
  }
}

export const renderCustomStep = (
  step: any,
  calculatedStepsCompleted: any,
  setCalculatedStepsCompleted: any,
  badges: string[],
) => {
  const customStep = step as CustomStep;
  if (!customStep.isCustom) return <></>;
  if (customStep.type === StepType.RsvpToWebinar)
    return (
      <AttendWebinarAccordionItem
        key={step.title}
        title={step.title}
        onCompleted={(completed) => {
          if (calculatedStepsCompleted.includes(step.route) && !completed) {
            setCalculatedStepsCompleted([
              ...calculatedStepsCompleted.filter((route: any) => route !== step.route),
            ]);
            return;
          }
          if (!calculatedStepsCompleted.includes(step.route) && completed) {
            setCalculatedStepsCompleted([...calculatedStepsCompleted, step.route]);
            return;
          }
        }}
        onExpanded={() => {
          setShownBadge(step.userId, step.route, step.associatedStageId, badges);
        }}
        rsvpStep={step as RsvpStep}
      />
    );
  return (
    <Accordion.Item
      key={step.title}
      icon={CustomStepRenderer.getIcon(customStep.type)}
      status={customStep.status}
      alert={CustomStepRenderer.getAlert(customStep)}
      statusText={CustomStepRenderer.getStatusText(customStep)}
      title={step.title}
      onStepClick={() => {
        setShownBadge(step.userId, step.route, step.associatedStageId, badges);
      }}>
      <CustomStepRenderer step={customStep} />
    </Accordion.Item>
  );
};

const mapStateToProps = (_: RootState) => {
  return {};
};

export default connect(mapStateToProps, {
  listenToWebinarRegistrations,
  listenToContentItems,
  listenToCustomSteps,
  listenToStepsStatus,
})(HomeContainer);
