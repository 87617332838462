import { Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AlertType,
  HelpContentView,
  IFrameGuide,
  InstructionalData,
  InstructionalModal,
} from '../../components';
import { PredefinedStep, StepStatus } from '../../repos';
import { ds160HelpData } from './DS160Data';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { FileSearchOutlined, MessageFilled, SolutionOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';
import { GradlyLoading } from '../../App';

interface DS160Props {
  step: PredefinedStep;
}

export const DS160View: React.FC<DS160Props> = (props) => {
  const { step } = props;
  const [showHelp, setShowHelp] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [insVisible, setInsVisible] = useState(false);
  const [insData, sInsData] = useState<InstructionalData>({
    title: (
      <>
        <SolutionOutlined /> {step.title}
      </>
    ),
    url: 'https://www.gradly.us/immigration',
    type: 'article',
    chatText: 'Chat with Immigration Expert',
    intercomMsg: `Hey! I've questions about DS-160 Application`,
  });

  const subSteps = [
    {
      title: step.title,
      content: {
        left: (
          <Row justify="start">
            <Spin
              style={{ display: 'flex' }}
              spinning={loading}
              indicator={<GradlyLoading height={'120%'} />}>
              <iframe
                width="780px"
                height="100%"
                style={{ minHeight: '70vh', margin: 'auto' }}
                src="https://ceac.state.gov/GenNIV/Default.aspx"
                title="DS 160 Application"
                frameBorder="0"
                onLoad={() => setLoading(false)}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </Spin>
          </Row>
        ),
        right: (
          <HelpContentView
            helpData={ds160HelpData}
            showHelp={showHelp}
            setShowHelp={setShowHelp}
            title={'DS 160 application'}
            ctaText={
              <>
                <MessageFilled />
                Chat with immigration expert
              </>
            }
            helpTitle={
              <>
                <FileSearchOutlined /> FORM SECTIONS
              </>
            }
          />
        ),
      },
      alert: {
        message: (
          <>
            You can complete your DS-160 application using the guide. To learn more about the DS-160
            application,{' '}
            <a
              onClick={() => {
                sInsData({
                  url: 'https://www.youtube-nocookie.com/embed/VFw5j0rYkKI?&autoplay=1',
                  title: (
                    <>
                      <SolutionOutlined /> {step.title}
                    </>
                  ),
                  type: 'video',
                  chatText: 'Chat with Immigration Expert',
                  intercomMsg: `Hey! I've questions about DS-160 Application.`,
                });
                setInsVisible(true);
              }}>
              watch this video
            </a>{' '}
            or{' '}
            <a
              onClick={() => {
                sInsData({
                  title: (
                    <>
                      <SolutionOutlined /> {step.title}
                    </>
                  ),
                  url: 'https://www.gradly.us/immigration',
                  type: 'article',
                  chatText: 'Chat with Immigration Expert',
                  intercomMsg: `Hey! I've questions about DS-160 Application.`,
                });
                setInsVisible(true);
              }}>
              read this article
            </a>
            .
          </>
        ),
        type: AlertType.Info,
      },
      flex: showHelp ? ['auto', '472px'] : ['auto', '150px'],
    },
  ];
  useEffect(() => {
    if (window.location.pathname.includes(step.route)) {
      setVisible(true);
    }
  }, [window.location.pathname]);

  return (
    <Accordion.Item
      key={step.route}
      icon={'solution'}
      highlighted
      status={step.status || StepStatus.Incomplete}
      title={step.title}
      showCohere
      onExpanded={() => {
        navigate!(`/${UserJourneyStageId.VisaApplication}/${step.route}`);
        setVisible(true);
        setShowHelp(false);
        setLoading(true);
      }}>
      <InstructionalModal visible={insVisible} setVisible={setInsVisible} {...insData} />
      <IFrameGuide
        showCohere
        journeyStageId={UserJourneyStageId.VisaApplication}
        step={step}
        visible={visible}
        setVisible={setVisible}
        setShowHelp={setShowHelp}
        subSteps={subSteps}
        titleIcon={<SolutionOutlined />}
      />
    </Accordion.Item>
  );
};
