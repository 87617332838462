import React from 'react';
import { UserJourneyStageId } from '../../config';
import { UserProfile } from '../../repos';
import { ProgressHeading } from '../ProgressHeading';
import { WelcomeUser } from '../WelcomeUser';
import Styles from './WelcomeUserWithProgressHeading.module.sass';

interface Props {
  title: string;
  stepCount: { total: number; left: number };
  currentUser?: UserProfile | null;
  stageID: UserJourneyStageId;
}

export const WelcomeUserWithProgressHeading: React.FC<Props> = (props) => {
  const { currentUser, title, stepCount, stageID } = props;

  return (
    <div className={Styles.header}>
      <ProgressHeading
        title={title}
        className={Styles.progressContainer}
        stepCount={stepCount}
        stageID={stageID}
      />
      <WelcomeUser className={Styles.greetingContainer} currentUser={currentUser} />
    </div>
  );
};
