import React, { FC } from 'react';
import { CheckOutlined, CheckCircleFilled } from '@ant-design/icons';
import { faFileInvoiceDollar } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { GradlyLoading } from '../../App';
import { RootState } from '../../redux';

import Styles from './PayInvoice.module.sass';
interface Props { }

export const PayInvoice: FC<Props> = (props) => {
  const { insuranceData, selectedPlan } = useSelector((state: RootState) => state.Insurance);
  if (!insuranceData) return <GradlyLoading />;
  return (
    <Row className={Styles.payment}>
      <Col xs={24} md={12} className={Styles.planInfo}>
        <span>{insuranceData?.insurancePlan}</span>
        <Row gutter={[0, 6]} className={Styles.priceTable}>
          <Col xs={12} md={10}>Invoice Amount:</Col>
          <Col xs={12} md={14}>
            <h2>${(insuranceData?.finalPremium || 0) - 25}</h2>
          </Col>
          <Col xs={12} md={10}>Deposit Amount:</Col>
          <Col xs={12} md={14}>
            <h2>$25</h2>
          </Col>
          <Col xs={12} md={10}>Total Premium:</Col>
          <Col xs={12} md={14}>
            <h1>${insuranceData?.finalPremium}</h1>
          </Col>
        </Row>

        <h2>
          {moment(insuranceData?.coverageStartDate, 'YYYY-MM-DD').format('MMM Do, YYYY')} -{' '}
          {moment(insuranceData?.coverageEndDate, 'YYYY-MM-DD').format('MMM Do, YYYY')}
        </h2>
        <ul className={Styles.liReasons}>
          <li key={1}>
            <CheckOutlined /> ${selectedPlan?.planFeatures.deductible} Annual Deductible
          </li>
          <li key={2}>
            <CheckOutlined /> {(selectedPlan?.planFeatures.coInsurance || 0) * 100}% Coinsurance
          </li>
          <li key={3}>
            <CheckOutlined /> ${selectedPlan?.planFeatures.outOfPocketMax} out of pocket maximum
          </li>
        </ul>
      </Col>
      <Col xs={24} md={12} className={Styles.payStatus}>
        {insuranceData?.invoicePaid ? (
          <Row align="middle" className={Styles.depositConfirmed}>
            <Row justify="center">
              <CheckCircleFilled /> <h1>Invoice Paid</h1>
              <Button
                type="primary"
                className={Styles.invoiceButton}
                target="_blank"
                href={insuranceData.invoiceURL}
                rel="noreferrer">
                Download receipt
              </Button>
            </Row>
          </Row>
        ) : (
          <Row align="middle" className={Styles.processingDeposit}>
            <Row justify="center">
              <FontAwesomeIcon icon={faFileInvoiceDollar} />
              <h1>Pay your Insurance premium</h1>
              <Row align="middle" className={Styles.paymentFail}>
                <Button
                  type="primary"
                  className={Styles.invoiceButton}
                  target="_blank"
                  href={insuranceData.invoiceURL}
                  rel="noreferrer">
                  Pay Premium
                </Button>
              </Row>
            </Row>
          </Row>
        )}
      </Col>
    </Row>
  );
};
