import React from 'react';
import { Widget } from '@typeform/embed-react';
import { Button, Row } from 'antd';
import { useState, useEffect } from 'react';
import { Accordion, AccordionModal, IntercomAPI } from '../../components';
import {
  PredefinedStep,
  StepStatus,
  UserProfile,
  markStepsStatus,
  LockModal,
} from '../../repos';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { StepID } from './HealthInsuranceStepContainer';
import { FileSyncOutlined, MessageOutlined } from '@ant-design/icons';
import Styles from './PurchaseInsurance.module.sass';

interface FileInsuranceProps {
  step: PredefinedStep;
  currentUser: UserProfile;
  university: string | undefined;
  lockModal: LockModal;
}

export const FileInsurance: React.FC<FileInsuranceProps> = (props) => {
  const { step, university, currentUser } = props;
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (window.location.pathname.includes(step.route)) {
      setShowModal(true);
    }
  }, [window.location.pathname]);
  return (
    <>
      {university && (
        <AccordionModal
          title={step.title}
          icon={<FileSyncOutlined />}
          step={step}
          visible={showModal}
          onCancel={() => setShowModal(false)}>
          <Row justify="center" className={Styles.help}>
            <p>Have questions about the process?</p>
            <Button
              type="primary"
              icon={<MessageOutlined />}
              onClick={() => {
                IntercomAPI.showWithMessagePreFilled(
                  'Hey! I have questions about filling insurance claim.',
                  false,
                );
              }}>
              {' '}
              Chat with Insurance Expert
            </Button>
          </Row>
          <Widget
            id={'w5G5EPs4'}
            onSubmit={(data) => {
              setTimeout(() => {
                setShowModal(false);
              }, 1500);
              markStepsStatus(
                currentUser.id,
                [UserJourneyStageId.HealthInsurance, StepID.FileInsuranceClaim],
                StepStatus.Complete,
              );
            }}
            hidden={{ gradlyuid: currentUser.id }}
            style={{ height: '70vh', marginTop: 24 }}
            hideHeaders
            hideFooter
            keepSession
            opacity={100}
          />
        </AccordionModal>
      )}
      <Accordion.Item
        key={step.route}
        icon={'file-protect'}
        highlighted
        lockOnComplete
        lockModal={props.lockModal}
        status={step.status || StepStatus.Incomplete}
        title={step.title}
        onExpanded={() => {
          setShowModal(true);
        }}></Accordion.Item>
    </>
  );
};
